export const favorites = [{
    id:1,
    categorie:'Electronique, Mobile, Iphone',
    titre:'Iphone 13 Pro VERT',
    src:'https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/iphone-13-pro-family-hero?wid=940&hei=1112&fmt=png-alpha&.v=1644969385433',
    total:14,
    go:'/produit/:ttyyu'
  },
  {
    id:2,
    categorie:'Electronique, Mobile, Iphone',
    titre:'Iphone 13 Pro MAX',
    src:'https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/iphone-13-pro-max-blue-select?wid=940&hei=1112&fmt=png-alpha&.v=1645552346295',
    total:24,
    go:'/produit/:ttyyu'
  },
  {
    id:3,
    categorie:'Electronique, Mobile, montre',
    titre:'Apple watch 7',
    src:'https://www.aptronixindia.com/media/catalog/product/s/i/silver_5.jpg',
    total:18,
    go:'/produit/:ttyyu'
  },
  {
    id:4,
    categorie:'Electronique, Ordinateur, Mac',
    titre:'Macbook Pro M1',
    src:'https://media.ldlc.com/r1600/ld/products/00/05/82/02/LD0005820214_1.jpg',
    total:14,
    go:'/produit/:ttyyu'
  },
 
  {
    id:5,
    categorie:'Electronique, Mobile, Iphone',
    titre:'Iphone 12 Pro MAX',
    src:'https://www.cdiscount.com/other/image-pc-sms-393x261-iphone-13-mini-p19-70157_211004155427.png',
    total:24,
    go:'/produit/:ttyyu'
  },


]