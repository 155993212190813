import React, { FormEvent, useEffect, useState } from 'react'
import { editUser, getAllRoles, getUserById } from '../../api/endpoint'
import { ToastContainer } from 'react-toastify'
import { alertDanger, alertSuccess } from '../../config/functions'
import InputField from '../../components/molecules/forms/InputField'
import SelectField from '../../components/molecules/forms/SelectField'
import BtnForm from '../../components/molecules/forms/BtnForm'
import { useParams } from 'react-router-dom'

type Props = {}

function UserEdit({ }: Props) {
    const { token } = useParams()
    const [user, setUser] = useState<any>(null)
    const [fullname, setFullname] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [role, setRole] = useState<string>("")
    const [roleList, setRoleList] = useState<Array<any>>([])

    const onSubmit = (form: FormEvent) => {
        form.preventDefault()
        const forms ={
            fullname,
            email,
            phone,
            role,
        }

        editUser(forms, token!)
        .then((response)=>{
            const {success, data} = response.data
            if(success === 1){
                alertSuccess(data);
            }else alertDanger(data)
        })
    }

    const getInfo = () => {
        getAllRoles()
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    setRoleList(data)
                }
            })
    }

    const getUser = () => {
        getUserById(token!)
            .then(response => {
                console.log(response)
                const { success, data } = response.data
                if (success === 1) {
                    setUser(data)
                }
            })
    }
    useEffect(() => {
        getInfo()
    }, [])


    useEffect(() => {
        getUser()
    }, [token])

    useEffect(()=>{
        setFullname(user?.nom_complet)
        setEmail(user?.email)
        setPhone(user?.tel)
        setRole(user?.id_role)
    }, [user])

   
    return (
        <div className="px-10">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <form action="" method="post" onSubmit={onSubmit}>

                <InputField
                    label="Nom Complet"
                    name="names"
                    value={fullname}
                    placeholder="entrer le nom complet"
                    onChange={(text: any) => setFullname(text.target.value)}
                    defaultValue={fullname}
                />
                <InputField
                    label="E-mail"
                    name="email"
                    type="email"
                    value={email}
                    placeholder="entrer l'adresse e-mail"
                    onChange={(text: any) => setEmail(text.target.value)}
                    defaultValue={email}
                />
                <InputField
                    label="Téléphone"
                    name="tel"
                    value={phone}
                    placeholder="entrer le numéro de telephone"
                    onChange={(text: any) => setPhone(text.target.value)}
                    defaultValue={phone}
                />

              {user?.id == 1 ? null :   <SelectField
                    label="Role"
                    name="type"
                    optionField="Séléctionner un role"
                    datas={roleList}
                    selected={role}
                    onChange={(text: any) => setRole(text.target.value)}
                />}
                <BtnForm value="enregistrer la modification" />
            </form>
        </div>
    )
}

export default UserEdit