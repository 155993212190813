import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Navigation from './navigations/Navigation'
import { Provider } from 'react-redux'
import Store from './states/store'
type Props = {}

const App = (props: Props) => {
 

  return (
    <Provider store={Store}>
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </Provider>

  )
}

export default App