import React, { FC } from 'react'
import { BiTrash } from 'react-icons/bi'
import { FiEdit3, FiEye } from 'react-icons/fi'
import { baseImage } from '../../config/variables'
import AText from '../atoms/AText'
import ATitle from '../atoms/ATitle'
import RoundedImage from './RoundedImage'

type Props = {
    item: any
    onToggle: Function
    onDelete:Function
}

const CatalogueItem:FC<Props> = ({item,onToggle, onDelete}) => {
  return (
    <div className="flex items-center border-b-2 border-gray-50">
    <RoundedImage
      src={baseImage+item?.cover}
      style="w-[80px] h-[80px] mr-5"
    />
    <div className="w-[55%]">
      <ATitle
        type='h3'
        label={{
          text: item.title,
          style: 'font-semibold'
        }}
      />

    </div>
    {/* <LinkText to={'/categorie/'+item.titre} style="bg-green-500 text-white rounded-[100%] p-2 m-1 text-sm"><FiEye/></LinkText> */}
    <AText style="bg-green-500 text-white rounded-[100%] text-sm p-2 m-1 active:bg-green-800"><FiEye onClick={()=> document.location="/catalogue/"+item.slug } /></AText>
    <AText style="bg-blue-500 text-white rounded-[100%] text-sm p-2 active:bg-blue-800"><FiEdit3 onClick={()=> onToggle(item) } /></AText>
    <AText style="bg-red-500 text-white rounded-[100%] p-2 m-1 text-sm active:bg-red-800"><BiTrash onClick={()=> onDelete(item.id)}/></AText>
  </div>
  )
}

export default CatalogueItem