import React, { FormEvent, useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { activeProduct, addProductImage, deleteProduct, deleteProductImage, getProductById } from "../../api/endpoint";
import LinkText from "../../components/atoms/LinkText";
import BtnForm from "../../components/molecules/forms/BtnForm";
import FileInput from "../../components/molecules/forms/FileInput";
import { alertDanger, alertSuccess, convertBase64 } from "../../config/functions";
import { baseImage } from "../../config/variables";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ToastContainer } from "react-toastify";
type Props = {};

const DetailProduct = (props: Props) => {
  const { id } = useParams();
  const [icons, setIcons] = useState<Array<any>>([]);
  const [previews, setPreviews] = useState<Array<any>>([]);
  const navigation = useNavigate();
  const [prd, setPrd] = useState<{
    prd_id: string;
    nom_prd: string;
    description: string;
    slug_prd: string;
    prixUSD: string | number;
    prixCDF: string | number;
    quantite: string | number;
    cover_prd: string;
    created_time: string | Date;
    active: string | boolean;
    prd_token: string;
    id_catg: string | number;
    nom_catalogue: string;
    id_cat: string | number;
    nom_categorie: string;
    galeries: Array<any>;
  } | null>(null);
  const MySwal = withReactContent(Swal)
  const getProd = ()=>{
    getProductById(id)
      .then((response) => {
        const { data, success } = response.data;
        if (success == 1) {
          setPrd(data);
        } else {
          alert("ce produit n'existe pas veuillez saisi un bon url");
          navigation("/");
        }
      })
      .catch((error) => {
        alert("vous devez passer une url valide");
        navigation("/");
      });
  }
  const handleProfils = async (e: any) => {
    const file = e.target.files;
    const premium: Array<any> = [];
    const previews: Array<any> = [];
    for (let index = 0; index < file.length; index++) {
      premium.push(file[index]);
      const lets = await convertBase64(file[index]);
      previews.push(lets);
    }
    setPreviews(previews);
    setIcons(premium);
  };
  const activePrd = () =>{
    Swal.fire({
      title: 'Voulez-vous vraiment '+ (prd!.active == "1"? ' desactiver ' : ' activer ') +' cet element ?',
      //showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Oui, '+  (prd!.active == "1"? ' desactiver ' : ' activer '),
      //denyButtonText: `Annuler`,
      cancelButtonText:'Annuler',
      icon:'question'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        activeProduct(prd!.prd_id)
        .then(response=>{
          const {success, data} = response.data
          if(success == 1){
            alertSuccess(data)
            getProd()
          }else alertDanger(data)
        })
      } 
    })
   
  }
  const deleleImage = (id: string) => {
    Swal.fire({
      title: 'Voulez-vous vraiment supprimer cet element ?',
      //showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimé',
      //denyButtonText: `Annuler`,
      cancelButtonText:'Annuler',
      icon:'question'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProductImage(id).then((response) => {
          const { success, data } = response.data;
          if (success == 1) {
            alert("suppression reussi");
            getProd()
          } else {
            alert("erreur survenu lors de la suppression ");
          }
        });
      } 
    })
    
  };

  const addGalerie = (e: FormEvent) =>{
    e.preventDefault();
    if(!icons.length) alertDanger('vous devez ajouter au moins une image');
    else{
      const formData = new FormData()
      for (let i = 0; i < icons.length; i++) {
        formData.append('galeries[]',icons[i])
      }
   
      addProductImage(prd!.prd_id,formData)
      .then((response)=>{
        console.log(response)
        const {success, data} = response.data
  
        if(success == 1){
          alertSuccess(data);
          setIcons([])
          setPreviews([])
          getProd()
        }else{
          alertDanger(data)
        }
      })
    }
   
  }

  const deletePrd = () =>{
    Swal.fire({
      title: 'Voulez-vous vraiment supprimer cet element ?',
      //showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimé',
      //denyButtonText: `Annuler`,
      cancelButtonText:'Annuler',
      icon:'question'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProduct(prd!.prd_id)
        .then((response)=>{
          const {success, data} = response.data
          if(success == 1) alertSuccess(data)
          else alertDanger(data)
        })
      } 
    })
    
  }
  useEffect(() => {
    getProd()
  }, []);

  return (
    <div>
         <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {prd ? (
        <div>
          <div className="bg-white rounded-2xl mx-auto flex w-max pr-5">
            <img
              src={`${baseImage}${prd.cover_prd}`}
              alt=""
              className="  object-cover w-[200px]   rounded-tl-2xl rounded-bl-2xl"
            />
            <div className="ml-4 pt-2 w-[400px]">
              <h3 className="font-semibold text-2xl">{prd.nom_prd}</h3>
              <h5 className="font-light text-[14px]">
                <span className="text-blue-500">{prd.nom_catalogue}</span>{" "}
                {" > "}
                {prd.nom_categorie}
              </h5>
              <h5
              onClick={()=> activePrd()}
                className={`font-light text-[14px] text-white w-max px-2 py-1 rounded-full cursor-pointer hover:scale-105 duration-300 ${
                  prd.active == "1" ? "bg-green-500" : "bg-red-500"
                }`}
              >
                {prd.active == "1" ? "en ligne" : "en attente"}
              </h5>
              <p className="mt-4 text-[16px]">
                <span className="font-semibold">USD :</span> {prd.prixUSD} $ USD{" "}
                <br />
                <span className="font-semibold">CDF :</span> {prd.prixCDF} CDF{" "}
                <br />
                <span className="font-semibold">Quantité :</span> {prd.quantite}{" "}
                <br />
              </p>
              <div className="flex mt-2">
                <LinkText
                  to={`/product/edit/${prd.prd_token}`}
                  style="flex justify-center items-center bg-green-500 px-3 py-1 rounded-full text-white w-max mr-2 text-[13px]  hover:scale-105 duration-300 cursor-pointer"
                >
                  <BiEdit size={14} className="mr-1" /> Modifier
                </LinkText>
                <div className="flex justify-center items-center bg-red-500 px-3 py-1 rounded-full text-white w-max text-[13px]  hover:scale-105 duration-300 cursor-pointer" onClick={()=> deletePrd()}>
                  <BiTrash size={14} className="mr-1" /> Supprimer
                </div>
              </div>
            </div>
          </div>
          <div className="flex mx-auto my-2 w-[640px] bg-white flex-wrap rounded-xl justify-center py-2">
            {prd.description}
          </div>
          <div className="flex mx-auto my-2 w-[640px] bg-white flex-wrap rounded-xl justify-center py-2">
            {prd.galeries.length
              ? prd.galeries.map((item, index) => (
                  <div
                    key={index}
                    className="w-[150px] h-[150px] m-1 rounded-xl relative"
                  >
                    <img
                      src={`${baseImage}${item.image}`}
                      alt=""
                      className="object-cover w-full h-full rounded-xl"
                    />
                    <div
                      className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-[20px] h-[20px] flex justify-center items-center cursor-pointer hover:scale-105 duration-300"
                      onClick={() => deleleImage(item.id)}
                    >
                      <BsTrash size={10} />
                    </div>
                  </div>
                ))
              : null}
          </div>
          <div className="my-2 w-[640px] bg-white flex-wrap rounded-xl justify-center py-2 mx-auto">
            <form action="" onSubmit={addGalerie}>
              <FileInput
                name="icons"
                label="Galerie Produit"
                value={previews}
                onChange={handleProfils}
                multiple
              />
              <BtnForm value="ajouter" />
            </form>
          </div>
        </div>
      ) : (
        "verifiez votre url et recommencer"
      )}
    </div>
  );
};
export default DetailProduct;
