import { useEffect, useState } from 'react'
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deleteProduct, getProducts } from '../../api/endpoint';
import ATitle from '../../components/atoms/ATitle';
import LinkText from '../../components/atoms/LinkText';
import ProductItem from '../../components/molecules/product-item';
import { alertDanger, alertSuccess } from '../../config/functions';


type Props = {}

const Products = (props: Props) => {
  const [produits, setProduits] = useState<Array<any>>([]);
  const navigate = useNavigate()
  const MySwal = withReactContent(Swal)
  const onDetail = (e:any) =>{
    navigate('/product/detail/'+e)
  }
  const getAllProducts = () => {
    getProducts()
      .then((response) => {
        const { success, data } = response.data
        if (success === 1) setProduits(data)
        else setProduits([])
      })
  }
  const deletePrd = (e:any) =>{
    Swal.fire({
      title: 'Voulez-vous vraiment supprimer cet element ?',
      //showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimé',
      //denyButtonText: `Annuler`,
      cancelButtonText:'Annuler',
      icon:'question'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProduct(e)
        .then(response=>{
          const {success, data} = response.data
          if(success == 1){
            alertSuccess(data)
            getAllProducts()
          }
          else alertDanger(data)
        })
      } 
    })
    
  }
  useEffect(() => {
    getAllProducts()
  }, [])
  return (
    <div className="w-full px-10">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ATitle
        type='h1'
        label={{
          text: 'Produits',
          style: 'text-[24px] text-orange-500 uppercase'
        }}
      />
      <div className="flex justify-between">
       
        <div className="w-full">
         
          <LinkText to='/products/add' style="flex items-center cursor-pointer bg-blue-500 rounded-xl text-white w-max px-3 mb-5 mt-2 py-1">
            Ajouter un produit <BsArrowRight/>
          </LinkText>
          <div className=" flex flex-wrap">
              {
                produits.length ? produits.map((item,index)=> <ProductItem key={index} onDetail={(e:any)=>onDetail(e)} data={item} onDelete={deletePrd} />): 'Aucun produit pour le moment'
              }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products