import React, { useEffect, useState } from 'react'
import { changeStatus, deleteUser, getAllRoles, getAllUsers } from '../../api/endpoint'
import { AiOutlineUser } from 'react-icons/ai'
import LinkText from '../../components/atoms/LinkText'
import { BsActivity, BsCheck, BsEye, BsEyeSlash, BsTrash } from 'react-icons/bs'
import { FaEdit } from 'react-icons/fa'
import { alertDanger, alertSuccess } from '../../config/functions'
import { ToastContainer } from 'react-toastify'

type Props = {}

const Users = (props: Props) => {
  const [role, setRole] = useState<Array<any>>([])
  const [userList, setUserList] = useState<Array<any>>([])
  const [selectTab, setSelectTab] = useState(0)
  const [loading, setLoading] = useState(false)

  const getInfo = () => {
    getAllRoles()
      .then(response => {
        const { success, data } = response.data

        if (success === 1) {
          setRole(data)
        }
      })
  }

  const getUserList = () => {
    getAllUsers()
      .then((reponse) => {
        const { success, data } = reponse.data
        if (success === 1) {
          setUserList(data)
        }
      })
  }
  const changeStatusFunc = (user: string) => {
    setLoading(true)
    changeStatus(user)
      .then(response => {
        setLoading(false)
        const { success, data } = response.data
        if (success === 1) {
          alertSuccess(data)
        } else alertDanger(data)
      })
  }
  const deleteUserFunc = (user: string) => {
    setLoading(true)
    deleteUser(user)
      .then(response => {
        setLoading(false)
        const { success, data } = response.data
        if (success === 1) {
          alertSuccess(data)
        } else alertDanger(data)
      })
  }
  const getRole = (roles: string) => {
    const value = role?.filter(item => item.id == roles)
    return value[0]?.label
  }
  useEffect(() => {
    getInfo()
  }, [])

  useEffect(() => {
    getUserList()
  }, [loading])
  const filtered = () => {
    if (selectTab) return userList?.filter(element => element.id_role === selectTab)
    return userList
  }
  return (
    <div className="px-10">

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="mb-3">
        <LinkText to='/users/create' style="text-sm bg-blue-500 text-white px-3 py-1 rounded-xl ">
          Ajouter un utilisateur
        </LinkText>
      </div>
      <ul className='flex'>
        <li className={`py-2 px-3 rounded-2xl mr-2 ${selectTab === 0 ? "bg-yellow-500" : "bg-gray-300"}  cursor-pointer hover:bg-yellow-500 duration-300`} onClick={() => setSelectTab(0)}>Tout</li>
        {
          role.length && role.map((item, index) => <li key={index} className={`py-2 px-3 rounded-2xl mr-2 ${selectTab === item.id ? "bg-yellow-500" : "bg-gray-300"}  cursor-pointer hover:bg-yellow-500 duration-300`} onClick={() => setSelectTab(item.id)}>{item.label}</li>
          )
        }

      </ul>
      {
        userList.length ? <table className='w-full mt-3'>
          <thead className='bg-gray-300 rounded-tr-xl text-left'>
            <tr>
              <td className='py-2 font-bold rounded-tl-xl px-3'>#</td>
              <td className='py-2 font-bold px-3'>Username</td>
              <td className='py-2 font-bold px-3'>Nom complet</td>
              <td className='py-2 font-bold px-3'>E-mail</td>
              <td className='py-2 font-bold px-3'>Telephone</td>
              <td className='py-2 font-bold px-3'>role</td>
              <td className='py-2 font-bold px-3'>statut</td>
              <td className='py-2 font-bold px-3'>action</td>
            </tr>
          </thead>
          <tbody>
            {
              filtered().map((item, index) => <tr key={index}>
                <td className='py-3 px-3'>{index + 1}</td>
                <td className='py-3 px-3 flex items-center'> <div className="w-[30px] h-[30px] flex items-center justify-center bg-gray-300 rounded-full mr-2">
                  <AiOutlineUser /></div> {item.username}</td>
                <td className='py-3 px-3 '> {item.nom_complet}</td>
                <td className='py-3 px-3 '> {item.email}</td>
                <td className='py-3 px-3 '> {item.tel}</td>
                <td className='py-3 px-3'>{getRole(`${item.id_role}`)}</td>
                <td className='py-3 px-3'>{item.active == 1 ? "active" : "bloquer"}</td>
                <td className='py-3 px-3'><div className="ml-4 flex">
                  {
                    item.id !== 1 && <div className={`${item.active === 1 ? "bg-blue-500" : "bg-red-700"} w-max px-2 py-1 text-white m-[1px] rounded-full flex justify-center items-center cursor-pointer hover:scale-105 duration-300`} onClick={() => changeStatusFunc(item?.id)}>
                      {
                        item.active === 1 ? <BsEye title='activer ou desactiver' /> : <BsEyeSlash title='activer ou desactiver' />
                      }
                    </div>
                  }
                  <LinkText to={`/users/edit/${item.token}`} style="bg-green-500 w-max px-2 py-1 text-white m-[1px] rounded-full flex justify-center items-center">
                    <FaEdit />
                  </LinkText>
                  {
                    item.id !== 1 && <div onClick={() => deleteUserFunc(item?.id)} className="bg-red-500 w-max px-2 py-1 text-white m-[1px] rounded-full flex justify-center items-center" >
                      <BsTrash />
                    </div>
                  }
                </div></td>
              </tr>)
            }
          </tbody>
        </table> : <p>Aucun utilisateur ajouté pour le moment</p>
      }
    </div>
  )
}

export default Users