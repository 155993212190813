import React, { FC, useEffect, useState } from 'react'
import ATitle from '../atoms/ATitle'
import CommandeItem from '../molecules/CommandeItem'
import { commande } from '../../api/endpoint'
import { FaShippingFast } from 'react-icons/fa'



const CommandeEnAttente = () => {
  const [cmd, setCmd] = useState<Array<any>>([])

  const getCommandeList = () => {
    commande()
      .then(response => {
        const { success, data } = response.data
        if (success === 1) {
          setCmd(data)
        }
      })
  }
  useEffect(() => {
    getCommandeList()
  }, [])
  return (
    <div className="w-[58%]  ">
      <ATitle label={{ text: "Commande en Attente de livraison" }} />
      {
        cmd.length ? <div className="bg-white shadow-sm rounded-xl mt-5">

          {
            cmd.map((item, index) => <CommandeItem item={item} key={index} />)
          }
        </div> : <div className="w-full h-[300px] bg-white flex flex-col items-center justify-center my-3">
          <div className="w-[100px] h-[100px] rounded-full bg-slate-200 flex items-center justify-center mr-3">
            <FaShippingFast size={50} className='text-orange-600'/>
          </div>
          <p className='text-gray-600 py-3'>Aucune commande pour le moment</p>
        </div>
      }

    </div>
  )
}

export default CommandeEnAttente