import React, { FC } from 'react'

type Props = {
  label: {
    text: string,
    style?: string | Object
  },
  style?: string | Object,
  icon?: any,
  type?: string
}


const ATitle: FC<Props> = ({ label, style = '', icon, type = 'h1' }) => {
  const selectType = () => {
    switch (type) {
      case 'h2':
        return <div className={`${style}`}>
          {icon}
          <h2 className={`${label.style}`}>{label.text}</h2>
        </div>
      case 'h3':
        return <div className={`${style}`}>
          {icon}
          <h3 className={`${label.style}`}>{label.text}</h3>
        </div>
      case 'h4':
        return <div className={`${style}`}>
          {icon}
          <h4 className={`${label.style}`}>{label.text}</h4>
        </div>
      case 'h5':
        return <div className={`${style}`}>
          {icon}
          <h5 className={`${label.style}`}>{label.text}</h5>
        </div>
      case 'h6':
        return <div className={`${style}`}>
          {icon}
          <h6 className={`${label.style}`}>{label.text}</h6>
        </div>
      default:
        return <div className={`${style}`}>
          {icon}
          <h1 className={`${label.style}`}>{label.text}</h1>
        </div>
    }
  }
  return (
    <>
      {selectType()}
    </>
  )
}
export default ATitle;