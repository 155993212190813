import React, { FormEvent, useEffect, useState } from 'react'
import { addCatalogue, editCatalogue, getCatalogues, removeCatalogue } from '../../api/endpoint'
import ATitle from '../../components/atoms/ATitle'
import BtnForm from '../../components/molecules/forms/BtnForm'
import FileInput from '../../components/molecules/forms/FileInput'
import InputField from '../../components/molecules/forms/InputField'
import { alertDanger, alertSuccess, convertBase64 } from '../../config/functions'
import { ToastContainer, toast } from 'react-toastify';
import CatalogueItem from '../../components/molecules/catalogue-item'

type Props = {}

const Catalogue = (props: Props) => {
    const [icon, setIcon] = useState<any>('');
    const [preview, setPreview] = useState<any>('');
    const [titre, setTitre] = useState<any>('');
    const [add, setAdd] = useState<boolean>(false);
    const [currentCatalogue, setCurrentCatalogue] = useState<any>('');
    const [catalogues, setCatalogues] = useState<Array<any>>([]);

    const getAllCatalogue = () => {
        getCatalogues()
            .then(response => {
                const { success, data } = response.data
                if (success == 1) {
                    setCatalogues(data)
                }
            })
    }

    const toggleForm = (item: any) => {
        setCurrentCatalogue(null)
        setAdd(true)
        if (item) setCurrentCatalogue(item)
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("titre", titre)
        formData.append("cover", icon)
        if (add) {
            editCatalogue(formData, currentCatalogue.token)
            .then(response => {
                const { data, success } = response.data
                if(success == 1){
                    alertSuccess("Modification réussi")
                    setTitre("")
                    setIcon("")
                    setPreview("")
                    getAllCatalogue()
                }
            })
        } else {
            if (titre !== '') {

                addCatalogue(formData)
                    .then(response => {
                        const { data, success } = response.data
                        if (success == 1) {
                            alertSuccess("Enregistrement réussi")
                            setTitre("")
                            setIcon("")
                            setPreview("")
                            getAllCatalogue()
                        } else {
                            alertDanger("vous devez remplir le titre")

                        }
                    })
            } else alertDanger("remplissez tous les champs")
        }
    }

    const handleProfil = async (e: any) => {
        const file = e.target.files[0]
        const preview = await convertBase64(e.target.files[0])
        setIcon(file)
        setPreview(preview)
    }
    const onDelete = (e: any) => {
        removeCatalogue(e)
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    getAllCatalogue()
                    alertSuccess(data)
                } else {
                    alertDanger(data)
                }

            })
    }
    useEffect(() => {
        getAllCatalogue()
    }, [])


    return (
        <div className="w-full px-10 flex">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="w-[40%] mr-4">
                <ATitle
                    type='h3'
                    label={{
                        text: add ? "Modification Catalogue" : "Ajout Catalogue",
                        style: 'text-[18px] text-gray-900 my-3'
                    }}
                />
                   {
            add ? <button onClick={()=>{
              setAdd(false)
              setTitre('')
              setIcon('')
              setPreview('')
              setCurrentCatalogue("")

            }} className="text-sm bg-blue-500 text-white px-3 py-1 rounded-xl mb-3">
              Ajouter une catalogue
            </button> : null
          }
                <div className="bg-white shadow-lg rounded-lg p-4 ">
                    <form action="" method="post" onSubmit={onSubmit}>
                        <InputField
                            label="Titre"
                            name="titre"
                            value={titre}
                            placeholder="entrer le titre de votre categorie"
                            defaultValue={`${currentCatalogue ? currentCatalogue.title : ""}`}
                            onChange={(text: any) => setTitre(text.target.value)}
                        />
                        <FileInput name="icon" label='icon' value={preview} onChange={handleProfil} />
                        <BtnForm value={add ? "Enregistrer la modification" : "ajouter"} />
                    </form>
                </div>
            </div>
            <div className="w-[53%] relative">
                <ATitle
                    type='h3'
                    label={{
                        text: "Liste",
                        style: 'text-[18px] text-gray-900 my-3'
                    }}
                />
                <div className=" bg-white h-[70vh]  w-[40%] p-3 rounded-lg fixed overflow-y-scroll">
                    {
                        catalogues.length ? catalogues.map((item, index) => <CatalogueItem key={index} item={item} onDelete={onDelete} onToggle={toggleForm} />) : <p>aucune categorie ajouter pour l'instant</p>
                    }
                </div>
            </div>

        </div>
    )
}
export default Catalogue