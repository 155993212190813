export const createStorage =  (name: string, data: object) =>{
   return localStorage.setItem(name, JSON.stringify(data));
}

export const getDataStore = (name: string) =>{
    const data = localStorage.getItem(name)
    if(data) return  JSON.parse(data);
    return null;
}

export const removeDataStore = (name: string) =>{
    return  localStorage.removeItem(name)
}