import React, { FC } from 'react'
import { Style } from 'util'
import AImage from '../atoms/AImage'

type Props = {
    src:string,
    style?: string | Object
}

const RoundedImage: FC<Props> = ({src,style}) => {
  return (
    <AImage
    src={src}
    style={`rounded-full object-cover ${style}`}
    />
  )
}
export default RoundedImage