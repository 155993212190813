import { favorites } from '../api/favorites'
import AlertStock from '../components/organisms/AlertStock'
import CommandeEnAttente from '../components/organisms/CommandeEnAttente'
import PeopleFavorite from '../components/organisms/PeopleFavorite'
import Statistique from '../components/organisms/Statistique'

type Props = {}

const Home = (props: Props) => {
  
  return (
    <div className="">
      <Statistique/>
      <div className="px-10 w-full overflow-x-hidden">
      <PeopleFavorite datas={favorites}/>
      </div>
      <div className="px-10 flex justify-between">
        <AlertStock />
        <CommandeEnAttente />
      </div>
    </div>
  )
}

export default Home