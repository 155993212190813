import React, { useEffect, useState } from 'react'
import { AiOutlineLogout, AiOutlineUser } from 'react-icons/ai'
import { BiMessageAltDetail } from 'react-icons/bi'
import { FiBell, FiChevronDown, FiSearch } from 'react-icons/fi'
import AText from '../atoms/AText'
import ATitle from '../atoms/ATitle'
import LinkText from '../atoms/LinkText'
import RoundedImage from '../molecules/RoundedImage'
import 'animate.css'
import { getDataStore } from '../../config/asyncStorage'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { userLogout } from '../../states/actions/usersActions'
import { AppDispatch } from '../../states/store'
import { useLocation, useNavigate } from 'react-router-dom'
type Props = {}

const MySwal = withReactContent(Swal)
const HeaderBar = (props: Props) => {
  const [showing, setShowing] = useState(false)
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const {pathname}=useLocation();
  const logout = ()=>{
  
    Swal.fire({
      title: 'deconnexion',
      text: 'Souhaitez-vous vraiment vous déconnecter ?',
      icon: 'question',
      confirmButtonText:"Oui se déconnecter",
      confirmButtonColor: '#DC3545'
    })
    .then(()=>{
      dispatch(userLogout())
      navigate('/login')
    })
  }
 
 

  return (
    <div className="w-[100%] h-20 flex items-center justify-between px-10  ">
      <ATitle
        label={{
          text: pathname !=='/'? pathname.replace('/','').toLocaleUpperCase() : 'Tableau de bord'.toLocaleUpperCase(),
          style: 'font-semibold text-gray-800 text-xl'
        }}
      />
      <div className="flex justify-evenly w-60 items-center">
        <LinkText style="hover:text-orange-500 duration-[0.3s] " to="/search"><FiSearch className='text-dark text-xl' /> </LinkText>
        <LinkText style="hover:text-orange-500 duration-[0.3s] relative " to="/search"><span className=" absolute right-0  h-3 w-3 rounded-full bg-orange-500 top-[-3px] opacity-1 text-[8px]  text-white flex items-center justify-center">1</span><BiMessageAltDetail className='text-dark text-xl' /> </LinkText>
        <LinkText style="hover:text-orange-500 duration-[0.3s] relative" to="/search"><span className=" absolute right-0  h-3 w-3 rounded-full bg-orange-500 top-[-3px] opacity-1 text-[8px] text-white flex items-center justify-center">3</span><FiBell className='text-dark text-xl' /> </LinkText>
        <div className=" w-[30%] flex items-center justify-center hover:cursor-pointer" onClick={() => setShowing(!showing)}>
          <RoundedImage
            src='https://www.simplitoo.fr/media/articles/preview_Iqvnb2A.jpeg' style='w-10 h-10 mr-2' />
          <FiChevronDown onClick={() => setShowing(!showing)} className='hover:cursor-pointer' />
        </div>
        <div className={`${(showing) ? 'animate__animated animate__fadeInRight  ' : ' animate__animated animate__fadeOutRight'} absolute right-10 top-20 w-44 bg-white rounded-md p-3 duration-[0.1s] z-20 border-2 border-orange-100 shadow-sm `}>
          <LinkText style="hover:text-orange-500 duration-[0.3s] text-sm " to="/account"><AText style="py-2 border-b-2 border-gray-50 flex items-center"><AiOutlineUser className='mr-2' /> Mon Compte</AText></LinkText>
          <div className="hover:text-orange-500 duration-[0.3s] text-sm  " onClick={logout} ><AText style=" py-2 flex items-center"><AiOutlineLogout className='mr-2' />Se deconnecter</AText></div>

        </div>
      </div>
    </div>
  )
}

export default HeaderBar