import React, { FC, useEffect, useState } from 'react'
import ATitle from '../atoms/ATitle'
import FavoriteItems from '../molecules/FavoriteItems'
import { favoritesProducts } from '../../api/endpoint'
import { TbArticleOff } from 'react-icons/tb'

type Props = {
  datas:Array<any>
}

const PeopleFavorite:FC<Props> = ({datas}) => {
  const [favorites, setFavorites] = useState<Array<any>>([])

  const getFavoriteList = ()=>{
    favoritesProducts()
    .then(response => {
      const { success, data } = response.data
      if (success === 1) {
          setFavorites(data)
      }
  })
  }
  useEffect(()=>{
      getFavoriteList()
  },[])
  return (
    <div className="">
        <ATitle
            label={
              {
                text:"Produits le plus vendus"
              }
            }
        />
        <div className="w-full overflow-x-scroll">
       {
        favorites.length ?  <div className="py-5 w-max flex">
        {
          favorites?.map((item,index)=> <FavoriteItems  item={item} key={index}/>)
        }
       
      </div> : <div className="w-full h-[300px] bg-white flex flex-col items-center justify-center my-3">
                    <div className="w-[100px] h-[100px] rounded-full bg-slate-200 flex items-center justify-center mr-3">
                        <TbArticleOff size={50} className='text-orange-600' />
                    </div>
                    <p className='text-gray-600 py-3'>Pas suffisamment de produit vendu pour le moment</p>                </div> 
       }
        </div>
    </div>
  )
}

export default PeopleFavorite