import { axiosInstance } from "../config/config.axios"

export const login =  (username:string, password:string)=>{
  return axiosInstance.post('/credentials/login.php',{username,password})
}
export const signUp =  (form: object)=>{
    return axiosInstance.post('/credentials/signup.php',form)
}
// categorie
export const addCategorie = (form: object) => {
  return axiosInstance.post('/categories/add.php', form)
}
export const editCategorie = (form: object, token: string) => {
  return axiosInstance.post('/categories/edit.php?token='+ encodeURI(token), form)
}
export const getAllCategorie = () => {
  return axiosInstance.get('/categories/')
}
export const deleteCategorie = (e:any) =>{
  return axiosInstance.get('/categories/delete.php?id='+e)
}

// catalogue
export const addCatalogue = (form: object) => {
  return axiosInstance.post('/catalogue/add.php', form)
}
export const getCatalogues = () => {
  return axiosInstance.get('/catalogue/')
}
export const getCatalogueById = (id: any) => {
  return axiosInstance.get('/catalogue/withId.php?token='+encodeURI(id))
}
export const removeCatalogue = (e:any) =>{
  return axiosInstance.get('/catalogue/delete.php?id='+e)
}
export const editCatalogue = (form: object, token: string) => {
  return axiosInstance.post('/catalogue/edit.php?token='+ encodeURI(token), form)
}

export const getCatCatById = (id: any) => {
  return axiosInstance.get('/catalogue/getCategories.php?id='+encodeURI(id))
}
export const removeCatCat = (id: any) => {
  return axiosInstance.get('/catalogue/deleteCategorie.php?id='+encodeURI(id))
}
export const addCatCat = (form: object,id: any) => {
  return axiosInstance.post('/catalogue/addCategorie.php?id='+encodeURI(id), form)
}

// products
export const addProduct = (form: object) => {
  return axiosInstance.post('/produits/add.php', form)
}
export const getProducts = () => {
  return axiosInstance.get('/produits/')
}
export const getProductById = (token: any) => {
  return axiosInstance.get('/produits/find.php?id='+ encodeURI(token))
}
export const editProduct = (form: object, id: string) => {
  return axiosInstance.post('/produits/edit.php?id='+encodeURI(id), form)
}
export const deleteProduct = (id: string) => {
  return axiosInstance.get('/produits/delete.php?id='+ encodeURI(id))
}
export const activeProduct = (id: string) => {
  return axiosInstance.get('/produits/activePrd.php?id='+ encodeURI(id))
}
export const deleteProductImage = (id: string) =>{
  return axiosInstance.get('/produits/imageDel.php?id='+ encodeURI(id))
}

export const addProductImage = (id:string, form: object) =>{
  return axiosInstance.post('/produits/imageAdd.php?id='+ encodeURI(id), form)
}
export const favoritesProducts = ()=>{
  return axiosInstance.get('/produits/topProduits.php')
}

export const ruptureStock = ()=>{
  return axiosInstance.get('/produits/rupture.php')
}
export const commande = ()=>{
  return axiosInstance.get('/commandes/')
}

// Promotion
export const addPromotion = (form: object) => {
  return axiosInstance.post('/promotions/add.php', form)
}
export const getAllPromotions = () => {
  return axiosInstance.get('/promotions/')
}
export const getPromotionById = (id: any) => {
  return axiosInstance.get('/promotions/withId.php?id='+encodeURI(id))
}
export const removePromotion = (e:any) =>{
  return axiosInstance.get('/promotions/delete.php?id='+e)
}
export const editPromotion = (form: object, id: string) => {
  return axiosInstance.post('/promotions/edit.php?id='+ encodeURI(id), form)
}



//role

export const getAllRoles = ()=>{
  return axiosInstance.get('/role/')
}

// users

export const getAllUsers = (role?:string)=>{
  if(role) axiosInstance.get('/users/?role='+ encodeURI(role))
  return axiosInstance.get('/users/')
}
export const deleteUser = (user:string) =>{
  return axiosInstance.get('/users/delete.php?user='+ encodeURI(user))
}
export const changeStatus = (user:string)=>{
  return axiosInstance.get('/users/active.php?user='+encodeURI(user))
}
export const createUser = (form:object)=>{
  return axiosInstance.post('/users/add.php', form)
}
export const getUserById = (user:string)=>{
  return axiosInstance.get('/users/find.php?user='+ encodeURI(user))
}
export const editUser = (form:object, user:string) =>{
  return axiosInstance.post('/users/edit.php?user='+encodeURI(user), form)
}

// statistiques

export const countVisitor = ()=>{
  return axiosInstance.get("/visiteurs/count.php")
}

export const  countSignUp = ()=>{
  return axiosInstance.get("/users/count.php")
}

export const countCategories = ()=>{
  return axiosInstance.get("/categories/count.php")
}

export const countProducts = ()=>{
  return axiosInstance.get('/produits/count.php')
}

export const countRevenus = ()=>{
  return axiosInstance.get('/produits/revenus.php')
}

export const countProductsSell = ()=>{
  return axiosInstance.get('/produits/sell.php')
}

export const countLivraison = ()=>{
  return axiosInstance.get('/produits/livraison.php')
}

//liste

export const livraisons = ()=>{
  return axiosInstance.get('/livraisons/')
}

export const getCmdById = (id: string) =>{
  return axiosInstance.get('/livraisons/getByToken.php?token='+ encodeURI(id))
}

export const getUsersByRole = (role: string) =>{
  return axiosInstance.get('/users/getUsersByrole.php?role='+encodeURI(role))
}
export const addLivreur = (form: object)=>{
  return axiosInstance.post('/livraisons/addlivreur.php', form)
}
export const addStatus = (form: object)=>{
  return axiosInstance.post('/livraisons/changeStatus.php', form)
}