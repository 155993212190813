import React, { FormEvent } from 'react'

type Props = {
  name: string,
  label: string,
  multiple?: boolean,
  value?: string | Array<any>,
  onChange: (e: FormEvent) => {}
}

function FileInput({ name, label, multiple = false, value, onChange }: Props) {
  const fily: any = document.querySelector(`#${name}`)
  const onclick = () => {
    fily.click()
  }
  return (
    <div className="flex flex-col">
      <label htmlFor={name}>{label} </label>
      <div className='w-full bg-gray-50 rounded-lg h-[100px] shadow-lg flex justify-center items-center flex-col mb-3 cursor-pointer hover:scale-[1.02] p-3' onClick={onclick}>
        {
          value?.length  ? (typeof value == "string" ? <img src={value} className="object-cover w-[80px] h-[80px] rounded-xl" /> : (
          <div className="flex">
            {
              value.map((item,index) => <img src={item} key={index} className="object-cover w-[80px] h-[80px] rounded-xl m-1" />)
            }
          </div>
         ))
            :
            <>
              <h3 className='font-semibold'>Ajouter une image</h3>
              <p className='text-[10px]'>cliquez pour ajouter</p>
            </>
        }
      </div>
      <input type="file" id={name} name={name} placeholder="entrer le titre de votre categorie" className='my-2 rounded-md p-3 outline-orange-500 transition-all duration-[0.3s] bg-gray-50 hidden' multiple={multiple} onChange={onChange} />
    </div>
  )
}

export default FileInput