import React, { FormEvent, useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { addCatCat, getAllCategorie, getCatalogueById, getCatCatById, removeCatCat } from '../../api/endpoint'
import CategorieItem from '../../components/molecules/CategorieItem'
import BtnForm from '../../components/molecules/forms/BtnForm'
import SelectField from '../../components/molecules/forms/SelectField'
import { alertDanger, alertSuccess } from '../../config/functions'
import { baseImage } from '../../config/variables'

type Props = {}

const DetailCatalogue = (props: Props) => {
  const { token } = useParams()
  const [data, setData] = useState<any>({})
  const [type, setType] = useState<any>("")
  const [categories, setCategories] = useState<Array<any>>([])
  const [myCat, setmyCat] = useState<Array<any>>([])



  const getCatalogue = () => {
    getCatalogueById(token)
      .then((response) => {
        const { success, data } = response.data
        if (success === 1) {
          setData(data)
        }
      })
  }
  const getCategories = () => {
    getAllCategorie()
      .then(response => {
        const { success, data } = response.data
        if (success === 1) setCategories(data)
        else setCategories([])
      })
  }
  const getAllCat = () => {
    getCatCatById(data.id)
      .then((response) => {
        const { success, data } = response.data
        if (success === 1) {
          setmyCat(data)
        }
      })
  }
  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("cat", type)
    if (type) {
      addCatCat(formData, data.id)
        .then(response => {
          const { data, success } = response.data
          if (success == 1) {
            alertSuccess("Enregistrement réussi")
            setType("")
            getAllCat()
          } else alertDanger(data)
        })
    }
  }

  const onDelete = (e: any) => {
    removeCatCat(e)
      .then(response => {
        const { success, data } = response.data
        if (success == 1) {
          getAllCat()
          alertSuccess(data)
        } else {
          alertDanger(data)
        }

      })
  }

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    getCatalogue()
  }, [])

  useEffect(() => {
    if (data) {
      getAllCat()
    }
  }, [data])
  
  return (
    <div className="w-full px-10 flex">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bg-white shadow-lg rounded-lg p-4 w-[38%]">
        <div className="flex items-center mb-5 cursor-pointer" onClick={() => document.location = '.'}>
          <FaArrowLeft />
          retourner
        </div>
        <div className="flex w-[100%]">
          <div className="w-[28%] mr-2">
            <div className="w-full">
              <img src={baseImage + data.cover} alt="" className="object-cover w-full h-20" />
            </div>
            <h3 className='bg-orange-500 text-white text-center'>{data.title}</h3>
          </div>
          <div className="w-[70%]">
            <form action="" method="post" onSubmit={onSubmit}>
              <label htmlFor="" className='font-semibold'>Ajouter une categorie</label>
              <SelectField
                datas={categories}
                name="Catalogue"
                label=''
                onChange={(text: any) => setType(text.target.value)}
                optionField='Ajouter une catalogue'
              />
              <BtnForm value={"ajouter"} />

            </form>
          </div>

        </div>

      </div>
      <div className=" bg-white h-[60vh] ml-[30%]   w-[40%] p-3 rounded-lg fixed overflow-y-scroll">
        <h3 className='font-bold mb-5'>Liste Categorie lié au Catalogue</h3>
        {
          myCat.length ? myCat.map((item, index) => <CategorieItem item={item} key={index} onDelete={(e: any) => onDelete(e)} />) : <p>aucune categorie ajouter pour l'instant</p>
        }
      </div>
    </div>
  )
}

export default DetailCatalogue