import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import HeaderBar from '../components/organisms/HeaderBar'
import SideBar from '../components/organisms/SideBar'
import Catalogue from '../screens/catalogue'
import DetailCatalogue from '../screens/catalogue/detail'
import Categories from '../screens/categorie/liste-categorie'
import AccountScreen from '../screens/credentials/account-screen'
import Login from '../screens/credentials/login-screen'
import Home from '../screens/Home'
import Add from '../screens/products/add'
import DetailProduct from '../screens/products/detail'
import EditProduct from '../screens/products/edit'
import Products from '../screens/products/products-home'
import HomePromotion from '../screens/promotion'
import Users from '../screens/users/usersHome.screen'
import UserCreate from '../screens/users/userCreate.screen'
import UserEdit from '../screens/users/userEdit.screen'
import LivraisonHome from '../screens/livraison/livraison-home'
import Detail from '../screens/livraison/detail'

type Props = {}

const Navigation = (props: Props) => {
  const {pathname}=useLocation();
  
  const routes = ()=>{
    return  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/products" element={<Products />} />
    <Route path="/products/add" element={<Add/>} />
    <Route path="/product/:id" element={<DetailProduct/>} />
    <Route path="/product/edit/:id" element={<EditProduct/>} />
    <Route path="/catalogue" element={<Catalogue/>} />
    <Route path="/catalogue/:token" element={<DetailCatalogue/>} />
    <Route path="/categories" element={<Categories />} />
    <Route path="/users" element={<Users />} />
    <Route path="/users/create" element={<UserCreate />} />
    <Route path="/users/edit/:token" element={<UserEdit />} />
    <Route path="/promotion" element={<HomePromotion />} />
    <Route path="/account" element={<AccountScreen />} />
    <Route path="/login" element={<Login />} />
    <Route path="/livraison" element={<LivraisonHome/>} />
    <Route path="/livraison/:token" element={<Detail/>} />
  </Routes>
  }
  const showing = ()=>{
    
    if(pathname === '/login') return routes()
    return <div className="w-full flex">
    <SideBar/>
    <div className="w-[80%] bg-gray-100 h-[100vh] relative overflow-y-auto overflow-x-hidden pb-10">
     <HeaderBar/>
    <div className="relative z-10">
    {
       routes()
     }
    </div>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='fixed bottom-0 z-0'><path fill="#eee" fillOpacity="1" d="M0,192L80,197.3C160,203,320,213,480,208C640,203,800,181,960,165.3C1120,149,1280,139,1360,133.3L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>

    </div>
</div>
  }
   return showing()
}

export default Navigation