import React from 'react'

type Props = {
    value: string
}

const BtnForm = ({value}: Props) => {
  return (
    <input type="submit" value={value} className='bg-orange-500 active:bg-orange-800 text-white py-2 px-5 rounded-xl' />

  )
}

export default BtnForm