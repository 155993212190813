import { Dispatch } from "redux";
import { login } from "../../api/endpoint";
import { createStorage, removeDataStore } from "../../config/asyncStorage";
import { USERS_actions } from "./constant";

export interface USER_LOGIN {
    readonly type: USERS_actions.USER_LOGIN,
    payload: {
        userData: object,
        userLoading: boolean,
        userError: boolean,
        userErrorMessage: null
    }
}

export interface USER_LAODING {
    readonly type: USERS_actions.USER_LAODING,
    payload: {
        userLoading: boolean,
        userError: boolean,
        userErrorMessage: null
    }
}
export interface USER_ERROR {
    readonly type: USERS_actions.USER_ERROR,
    payload: {
        userLoading: boolean,
        userError: boolean,
        userErrorMessage: string
    }
}

export interface USER_LOGOUT {
    readonly type: USERS_actions.USER_LOGOUT,
    payload: {
        userData: null,
        userLoading: boolean,
        userError: boolean,
        userErrorMessage: null
    }
}

export type userActionsType = USER_LOGIN | USER_ERROR | USER_LAODING | USER_LOGOUT;

export const userLogin = (form: { username: string, password: string }) => {
    return (dispatch: Dispatch<userActionsType>) => {
        dispatch({
            type: USERS_actions.USER_LAODING,
            payload: {
                userLoading: true,
                userError: false,
                userErrorMessage: null
            }
        })
        console.log(form)
        login(form.username, form.password)
            .then(({ data }) => {
                console.log(data)
                if (data.success == 1) {
                    dispatch({
                        type: USERS_actions.USER_LOGIN,
                        payload: {
                            userData: data.data,
                            userLoading: false,
                            userError: false,
                            userErrorMessage: null
                        }
                    })
                    createStorage('login-user', data.data)
                } else {
                    dispatch({
                        type: USERS_actions.USER_ERROR,
                        payload: {
                            userLoading: false,
                            userError: true,
                            userErrorMessage: "nom d'utilisateur ou mot de passe incorrect"
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error)
                dispatch({
                    type: USERS_actions.USER_ERROR,
                    payload: {
                        userLoading: false,
                        userError: true,
                        userErrorMessage: error.message
                    }
                })
            })
    }
}

export const clearUserError = () => {
    return (dispatch: Dispatch<userActionsType>) => {
        dispatch({
            type: USERS_actions.USER_ERROR,
            payload: {
                userLoading: false,
                userError: false,
                userErrorMessage: ''
            }
        })

    }
}

export const userLogout = () => {
    return (dispatch: Dispatch<userActionsType>) => {
        dispatch({
            type: USERS_actions.USER_LOGOUT,
            payload: {
                userData: null,
                userLoading: false,
                userError: false,
                userErrorMessage: null
            }
        })
        removeDataStore('login-user')
    }
}