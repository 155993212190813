import React, { FormEvent, useEffect, useState } from 'react'
import ATitle from '../../components/atoms/ATitle'
import CategorieItem from '../../components/molecules/CategorieItem'
import allData from '../../api/categorie.json'
import { categorie } from '../../config/types'
import InputField from '../../components/molecules/forms/InputField'
import SelectField from '../../components/molecules/forms/SelectField'
import FileInput from '../../components/molecules/forms/FileInput'
import BtnForm from '../../components/molecules/forms/BtnForm'
import { alertDanger, alertSuccess, convertBase64 } from '../../config/functions'
import { ToastContainer, toast } from 'react-toastify';
import { addCategorie, deleteCategorie, editCategorie, getAllCategorie } from '../../api/endpoint'
import { baseImage } from '../../config/variables'



const Categories = () => {
  const data = allData;
  const [add, setAdd] = useState<boolean>(false);
  const [titre, setTitre] = useState<any>('');
  const [type, setType] = useState<any>('');
  const [icon, setIcon] = useState<any>('');
  const [preview, setPreview] = useState<any>('');
  const [currentCategorie, setCurrentCategorie] = useState<any>(null)
  const [categories, setCategories] = useState<Array<any>>([])
 

  const toggleForm = (item: any) => {
    setCurrentCategorie(null)
    setAdd(true)
    if (item) {
      setCurrentCategorie(item)
      // setPreview('')
      // setPreview(baseImage+ currentCategorie.icon)
    }
  }
  const handleProfil = async (e: any) => {
    const file = e.target.files[0] 
    const preview = await convertBase64(e.target.files[0])
    setIcon(file)
    setPreview(preview)
  }
  const getCategories = ()=>{
    getAllCategorie()
    .then((response)=>{
      const {success, data} = response.data
      if(success=== 1) setCategories(data)
      else setCategories([])
    })
  }
  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('titre', titre);
    formData.append('type', type);
    formData.append('icon', icon)
    if(add){
      editCategorie(formData, currentCategorie.token)
      .then((response)=>{
        const { success, data } = response.data

        if (success === 1) {
          getCategories()
          setTitre('')
          setType('')
          setIcon('')
          setPreview('')
        }

      })
    }else{
      if (!titre) {
        alertDanger("vous devez remplir le titre")
      } else {
        addCategorie({ titre, type, icon })
          .then((response) => {
            const { success, data } = response.data
            if (success === 1) {
              alertSuccess(data)
              getCategories()
              setTitre('')
              setType('')
              setIcon('')
              setPreview('')
            } else {
              alertDanger(data)
            }
          })
      }
    }
  }

  const onDelete = (e: any) =>{
    deleteCategorie(e)
    .then(response=>{
      const {success, data} = response.data
      if(success === 1){
        getCategories()
        alertSuccess(data)
      }else{
        alertDanger(data)
      }
      
    })
  }

  useEffect(()=>{
    getCategories()
  }, [])
  return (
    <div className="w-full px-10">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ATitle
        type='h1'
        label={{
          text: 'Categorie',
          style: 'text-[24px] text-orange-500 uppercase'
        }}
      />
      <div className="flex justify-between">
        <div className="w-[45%]">
          <ATitle
            type='h3'
            label={{
              text: `${(add) ? "Modifier une categorie" : "Ajouter une categorie"} `,
              style: 'text-[18px] text-gray-900 my-3'
            }}
          />
          {
            add ? <button onClick={()=>{
              setAdd(false)
              setTitre('')
              setType('')
              setIcon('')
              setPreview('')
              setCurrentCategorie("")

            }} className="text-sm bg-blue-500 text-white px-3 py-1 rounded-xl mb-3">
              Ajouter une categorie
            </button> : null
          }
          <div className="bg-white shadow-lg rounded-lg p-4">
            <form action="" method="post" onSubmit={onSubmit}>

              <InputField
                label="Titre"
                name="titre"
                value={titre}
                placeholder="entrer le titre de votre categorie"
                defaultValue={`${currentCategorie ? currentCategorie.label : ""}`}
                onChange={(text: any) => setTitre(text.target.value)}
                
              />
              <SelectField
                label="type de categorie"
                name="type"
                optionField="Séléctionner un type"
                datas={[
                  { id: "blog", label: "article" },
                  { id: "produit", label: "produit" }
                ]}
                onChange={(text: any) => setType(text.target.value)}
                selected={currentCategorie?.type_pr}
              />
            
              <FileInput name="icon" label='icon' value={preview} onChange={handleProfil} />
              <BtnForm value={add ? "enregistrer la modification" : "ajouter"}/>
            </form>
          </div>
        </div>
        <div className="w-[53%] relative">
          <ATitle
            type='h3'
            label={{
              text: "Liste",
              style: 'text-[18px] text-gray-900 my-3'
            }}
          />
          <div className=" bg-white h-[70vh]  w-[40%] p-3 rounded-lg fixed overflow-y-scroll">
            {
               categories.length ? categories.map((item, index) => <CategorieItem item={item} key={index} onDelete={(e:any)=> onDelete(e)}  onToggle={toggleForm} />): <p>aucune categorie ajouter pour l'instant</p>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Categories