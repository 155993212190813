import React, { FC } from 'react'

type Props = {
    label: string
    name: string
    optionField: string
    datas: Array<any>
    onChange: Function
    selected?: any
}

const SelectField: FC<Props> = ({ label, name, optionField, datas = [], onChange, selected }) => {
    return (
        <div className="flex flex-col">
            <label htmlFor={name}>{label}</label>
            <select
                name={name}
                id={name}
                className='my-4 rounded-md p-3 outline-orange-500 transition-all duration-[0.3s] bg-gray-50'
                onChange={(input)=> onChange(input)}
                value={selected}
            >
                <option value="">{optionField}</option>
                {
                    datas && datas.map((item) => <option key={item.id} value={item.id} selected={(selected ===item.id)}>{item.label} {item.title}</option>)
                }
            </select>
        </div>
    )
}

export default SelectField