import React, { FC } from 'react'
import { Link } from 'react-router-dom'

type Props = {
    children: React.ReactNode,
    to?:string,
    style?:string | Object
}

const LinkText:FC<Props> = ({children,to,style}) => {
  return (
    <Link to={`${to}`} className={`${style}`} >
        {children}
    </Link>
  )
}

export default LinkText