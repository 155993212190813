import { USERS_actions } from "../actions/constant";
import { userActionsType } from "../actions/usersActions";

type initialStateType = {
    userData: object | null;
    userLoading: boolean;
    userError: boolean;
    userErrorMessage: string | null;
}

const initialState: initialStateType = {
    userData: null,
    userLoading: false,
    userError: false,
    userErrorMessage: null
}

const usersReducers = (state: initialStateType = initialState, action: userActionsType) => {
    switch (action.type) {
        case USERS_actions.USER_LOGIN:
            return {
                ...state,
                userData: action.payload.userData,
                userLoading: action.payload.userLoading,
                userError: action.payload.userError,
                userErrorMessage: action.payload.userErrorMessage
            }
        case USERS_actions.USER_LAODING:
            return {
                ...state,
                userLoading: action.payload.userLoading,
                userError: action.payload.userError,
                userErrorMessage: action.payload.userErrorMessage
            }
        case USERS_actions.USER_ERROR:
            return {
                ...state,
                userLoading: action.payload.userLoading,
                userError: action.payload.userError,
                userErrorMessage: action.payload.userErrorMessage
            }
        case USERS_actions.USER_LOGOUT:
            return {
                ...state,
                userData: null,
                userLoading: false,
                userError:false,
                userErrorMessage: null
            }
        default:
            return state;
    }
}

export default usersReducers;