import React, { FormEvent, useEffect, useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs';
import { ToastContainer } from 'react-toastify';
import { addProduct, getAllCategorie, getCatalogues, getCatCatById } from '../../api/endpoint'
import ATitle from '../../components/atoms/ATitle';
import LinkText from '../../components/atoms/LinkText';
import BtnForm from '../../components/molecules/forms/BtnForm';
import FileInput from '../../components/molecules/forms/FileInput';
import InputField from '../../components/molecules/forms/InputField';
import SelectField from '../../components/molecules/forms/SelectField';
import { alertDanger, alertSuccess, convertBase64 } from '../../config/functions';

type Props = {}

function Add({ }: Props) {
    const [data, setData] = useState([])
    const [icon, setIcon] = useState<any>('');
    const [preview, setPreview] = useState<any>('');
    const [icons, setIcons] = useState<Array<any>>([]);
    const [previews, setPreviews] = useState<Array<any>>([]);
    const [titre, setTitre] = useState<any>('');
    const [type, setType] = useState<any>('');
    const [cat, setCat] = useState<any>('');
    const [prixUSD, setPrixUSD] = useState<any>('');
    const [prixCDF, setPrixCDF] = useState<any>('');
    const [qte, setQte] = useState<any>('');
    const [description, setDescription] = useState<any>('');
    const [catalogues, setCatalogues] = useState<Array<any>>([]);
    const [categories, setCategories] = useState<Array<any>>([]);
    const getAllCatalogue = () => {
        getCatalogues()
          .then(response => {
            const { success, data } = response.data
            if (success == 1) {
              setCatalogues(data)
            }
          })
      }
    
      const getCategories = () => {
        getAllCategorie()
          .then((response) => {
            const { success, data } = response.data
            if (success === 1) setData(data)
            else setData([])
          })
      }
    const getAllCat = (id: string) => {
        getCatCatById(id)
            .then((response) => {
                const { success, data } = response.data
                if (success === 1) {
                    setCategories(data)
                }
            })
    }

    const handleProfil = async (e: any) => {
        const file = e.target.files[0]
        const preview = await convertBase64(e.target.files[0])
        setIcon(file)
        setPreview(preview)
    }

    const handleProfils = async (e: any) => {
        const file = e.target.files
        const premium: Array<any> = []
        const previews: Array<any> = []
        for (let index = 0; index < file.length; index++) {
            premium.push(file[index])
            const lets = await convertBase64(file[index])
            previews.push(lets)
        }
        setPreviews(previews)
        setIcons(premium)
    }

console.log(categories)
    useEffect(() => {
        getCategories()
    }, [])

    useEffect(() => {
        getAllCatalogue()
    }, [])
    useEffect(() => {
        if (type) {
            getAllCat(type)
        }
    }, [type])
    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("titre", titre)
        formData.append("type", type)
        formData.append("cat", cat)
        formData.append("icon", icon)
        formData.append("prixUSD", prixUSD)
        formData.append("prixCDF", prixCDF)
        formData.append("qte", qte)
        formData.append("description", description)
        for (let i = 0; i < icons.length; i++) {
            formData.append("icons[]", icons[i])
        }

        if (titre && type && cat && prixUSD && prixCDF && qte && icon && icons.length) {
            addProduct(formData)
                .then(response => {
                  console.log(response)
                    const { data, success } = response.data
                    if (success == 1) {
                        alertSuccess(data);
                        setIcon("")
                        setPreview("")
                        setIcons([])
                        setPreviews([])
                        setTitre("")
                        setType("")
                        setCat("")
                        setPrixCDF("")
                        setPrixUSD("")
                        setQte("")
                        setDescription("")
                    } else {
                        alertDanger(data);
                    }
                })
        } else alertDanger("vous devez remplir tout les champs")


    }
    return (
        <div className="w-full px-10 ">
          <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LinkText to='/products' style="flex items-center cursor-pointer bg-blue-500 rounded-xl text-white w-max px-3">
        <BsArrowLeft />  <span className='ml-2'>retour</span> 
      </LinkText>
       <div className="w-full px-10">
          <ATitle
            type='h3'
            label={{
              text: "Ajouter un produit",
              style: 'text-[18px] text-gray-900 my-3'
            }}
          />
          <div className="bg-white shadow-lg rounded-lg p-4">
            <form action="" method="post" onSubmit={onSubmit}>
              <InputField
                label="Nom produit"
                name="titre"
                value={titre}
                placeholder="entrer le titre du produit"
                defaultValue={titre}
                onChange={(text: any) => setTitre(text.target.value)}
              />

              <SelectField
                label="Catalogue"
                name="type"
                optionField="Séléctionner"
                datas={catalogues}
                onChange={(text: any) => setType(text.target.value)}
              />

              {
                type && <SelectField
                  label="Categorie"
                  name="type"
                  optionField="Séléctionner"
                  datas={categories}
                  onChange={(text: any) => setCat(text.target.value)}

                />
              }
              <InputField
                label="Prix USD"
                name="prixUsd"
                type='number'
                value={prixUSD}
                defaultValue={prixUSD}
                placeholder="entrer le montant"
                onChange={(text: any) => setPrixUSD(text.target.value)}
              />

              <InputField
                type='number'
                label="Prix CDF"
                name="prixCdf"
                value={prixCDF}
                defaultValue={prixCDF}
                placeholder="entrer le montant"
                onChange={(text: any) => setPrixCDF(text.target.value)}
              />
              <InputField
                type='number'
                label="Quantité"
                name="qte"
                value={qte}
                defaultValue={qte}
                placeholder="entrer le montant"
                onChange={(text: any) => setQte(text.target.value)}
              />
              <div className="">
                <label htmlFor="">Descrition</label>
                <textarea className='my-2 rounded-md p-3 outline-orange-500 transition-all duration-[0.3s] bg-gray-50 w-full' onChange={(text: any) => setDescription(text.target.value)} >{description}</textarea>
              </div>
              <FileInput name="icon" label='Image de couverture' value={preview} onChange={handleProfil} />
              <FileInput name="icons" label='Galerie Produit' value={previews} onChange={handleProfils} multiple />
              <BtnForm value="ajouter" />
            </form>
          </div>
        </div>
       </div>
    )
}

export default Add