import { FC } from 'react'
import { Link } from 'react-router-dom'

type Props = {
    to?:string,
    style?:string | Object
    icon?: any,
    label : {
        text: string,
        style?:string | Object
    }
}

const ItemSidebar:FC<Props> = ({label,to,style,icon}) => {
  return (
    <Link to={`${to}`} className={`${style}`}>   
        <li className={`${label.style}`}>{icon} {label.text}</li>
    </Link>
  )
}

export default ItemSidebar