import React, { FormEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addLivreur, addStatus, getCmdById, getUsersByRole } from '../../api/endpoint'
import { Accordion, Button, Label, Select, Table } from 'flowbite-react';
import { Avatar } from 'flowbite-react';
import { baseImage } from '../../config/variables';
import { BiCheck, BiChevronLeft, BiCloset } from 'react-icons/bi';
import { Toast } from 'flowbite-react';
type Props = {}

const Detail = (props: Props) => {
  const { token } = useParams()
  const [data, setData] = useState<any>(null)
  const [total, setTotal] = useState(0)
  const [livreurs, setLivreurs] = useState([])
  const [livreur, setLivreur] = useState<any>(null)
  const [statut, setStatut] = useState<any>(null)

  const getDetails = () => {
    if (token) {
      getCmdById(token)
        .then(response => {
          const { success, data } = response.data
          if (success === 1) {
            setData(data)
            let tt = 0
            for (let index = 0; index < data.lists.length; index++) {
              const tab = data.lists
              tt += (parseFloat(tab[index].prd_quantite) * tab[index].prixUSD)
            }
            setTotal(tt)
          }
        })
    }
  }
  const getLivreurs = () => {
    getUsersByRole('5')
      .then(response => {
        const { success, data } = response.data

        if (success == "1") {
          setLivreurs(data)
        }
      })
  }
  useEffect(() => {
    getDetails()
  }, [])
  useEffect(() => {
    getLivreurs()
  }, [])

  function RefreshButton() {
    window.location.reload();
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    const formData = new FormData()

    formData.append("livreur", livreur)
    formData.append("cmd", data?.cmd?.cmd_id)

    if (livreur) {
      addLivreur(formData)
        .then(response => {

          const { success, data } = response.data
          if (success == "1") {
            alert(data)
            RefreshButton()
          } else {
            alert(data)
          }

        })
        .catch(err => {
          alert(err.message)
        })
    }
    else {
      alert("vous devez d'abord choisir un livreur")
    }

  }

  const onSubmitStatus = (e: FormEvent) => {
    e.preventDefault()
    const formData = new FormData()

    formData.append("statut", statut)
    formData.append("cmd", data?.cmd?.cmd_id)

    if (statut) {
      addStatus(formData)
        .then(response => {
          console.log(response)
          const { success, data } = response.data
          if (success == "1") {
            alert(data)
            RefreshButton()
          } else {
            alert(data)
          }

        })
        .catch(err => {
          alert(err.message)
        })
    }
    else {
      alert("vous devez d'abord choisir un livreur")
    }

  }
  return (
    <div className="w-[90%]  ml-10">

      <Button color="gray" onClick={() => document.location = '/livraison'}>
        <BiChevronLeft className="ml-2 h-5 w-5" />
        Retour
      </Button>
      <Table>
        {
          data ? <Table.Body className="divide-y">
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white uppercase">
                Reference commande
              </Table.Cell>
              <Table.Cell className='uppercase'>
                {data?.cmd?.cmd_token}
              </Table.Cell>

            </Table.Row>
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white uppercase">
                Client
              </Table.Cell>
              <Table.Cell>
                {data?.cmd?.cl_fullname}
              </Table.Cell>

            </Table.Row>
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white uppercase">
                telephone
              </Table.Cell>
              <Table.Cell>
                {data?.cmd?.cl_tel}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white uppercase">
                email
              </Table.Cell>
              <Table.Cell>
                {data?.cmd?.cl_email}
              </Table.Cell>

            </Table.Row>
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white uppercase">
                adresse de livraison
              </Table.Cell>
              <Table.Cell className='text-blue-600'>
                {data?.cmd?.adresse} <br />
                réf: {data?.cmd?.ref}
              </Table.Cell>

            </Table.Row>
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white uppercase">
                Personne a contacté
              </Table.Cell>
              <Table.Cell className='text-blue-600'>
                {data?.cmd?.contact_name} <br />
                tel : {data?.cmd?.contact_number}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white uppercase">
                Information importante
              </Table.Cell>
              <Table.Cell className='text-blue-600'>
                {data?.cmd?.additional_text}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white uppercase">
                Statut livraison
              </Table.Cell>
              <Table.Cell className='text-blue-600'>
                {data?.cmd?.cancel =="1" ? "annulée" : data?.cmd?.current_pos}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white uppercase">
                livreur attribué
              </Table.Cell>
              <Table.Cell>
                {data?.cmd?.lv_fullname} <br />
                {data?.cmd?.lv_tel}
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white uppercase">
                date commande
              </Table.Cell>
              <Table.Cell>
                {data?.cmd?.data_created}
              </Table.Cell>
            </Table.Row>

          </Table.Body>
            : null}

      </Table>
      {
        (data?.cmd?.cancel !== 1 && data?.cmd?.cmd_active !== 1) ? <Accordion collapseAll className='w-full bg-white mt-4'>
        <Accordion.Panel>
          <Accordion.Title>
            Livreur
          </Accordion.Title>
          <Accordion.Content>
            <form action="" method="post" onSubmit={onSubmit}>
              <div
                className="max-w-md"
                id="select"
              >
                <div className="mb-2 block">
                  <Label
                    htmlFor="livreurs"
                    value="Selectionner un livreur"
                  />
                </div>
                <Select
                  id="countries"
                  required
                  className='mb-2'
                  onChange={(text) => setLivreur(text.target.value)}
                >
                  <option value="">
                    Séléctionner un livreur
                  </option>
                  {
                    livreurs?.length ? livreurs.map((item: any, index) => <option key={index} value={item?.id}>
                      {item.nom_complet}
                    </option>) : null
                  }
                </Select>

                <Button type="submit" >
                  Enregistrer
                </Button>
              </div>
            </form>
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title>
            statut livraison
          </Accordion.Title>
          <Accordion.Content>
            <form action="" method="post" onSubmit={onSubmitStatus}>
              <div
                className="max-w-md"
                id="select"
              >
                <div className="mb-2 block">
                  <Label
                    htmlFor="progession livraison"
                    value="Selectionner une progression"
                  />
                </div>
                <Select
                  id="count"
                  required
                  className='mb-2'
                  onChange={(text) => setStatut(text.target.value)}
                >
                  <option value="">Selectionner un statut</option>
                  <option value="en préparation">
                    en préparation
                  </option>
                  <option value="en cours">
                    en cours
                  </option>
                  <option value="livrée">
                    livrée
                  </option>
                </Select>

                <Button type="submit">
                  Enregistrer
                </Button>
              </div>
            </form>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion> : null
      }
      <h3 className='py-5 text-2xl'>Produits commandé</h3>
      <Table>
        <Table.Head>
          <Table.HeadCell>
            N°
          </Table.HeadCell>
          <Table.HeadCell>
            Nom produit
          </Table.HeadCell>
          <Table.HeadCell>
            quantité
          </Table.HeadCell>
          <Table.HeadCell>
            Prix/unit
          </Table.HeadCell>
          <Table.HeadCell>
            prix total
          </Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {
            data?.lists?.length ? data.lists.map((item: any, index: any) => <Table.Row key={index}>
              <Table.Cell>
                {
                  index + 1
                }
              </Table.Cell>
              <Table.Cell className='flex text-sm cursor-pointer' onClick={() => document.location = '/product/' + item.prd_token}>
                <Avatar
                  alt="avatar of Jese"
                  img={baseImage + item.cover_prd}
                />
                <p className='w-[60%]'>
                  {
                    item.nom_prd
                  }
                </p>

              </Table.Cell>
              <Table.Cell>
                {item.prd_quantite}
              </Table.Cell>
              <Table.Cell>
                $ {item.prixUSD}
              </Table.Cell>
              <Table.Cell>
                $ {parseFloat(item.prd_quantite) * parseFloat(item.prixUSD)}
              </Table.Cell>
            </Table.Row>) : null
          }
        </Table.Body>
      </Table>
      <Table className='mt-5 w-[40%]'>
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 ">
          <Table.Cell className=" font-medium text-gray-900 dark:text-white uppercase">
            livraison
          </Table.Cell>
          <Table.Cell className='text-xl'>
            $ 2.00
          </Table.Cell>
        </Table.Row>
        <Table.Row className=" dark:border-gray-700 dark:bg-gray-800 ">
          <Table.Cell className=" font-medium text-gray-900 dark:text-white uppercase">
            total à payez
          </Table.Cell>
          <Table.Cell className='text-2xl'>
            $ {total + 2}
          </Table.Cell>
        </Table.Row>
      </Table>

    </div>
  )
}

export default Detail