import React, { FC } from 'react'

type Props = {
    src: any,
    style?: string | Object
}

const AImage:FC<Props> = ({src,style}) => {
  return (
    <img src={`${src}`} alt="" className={`${style}`}/>
  )
}

export default AImage