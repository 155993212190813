import axios from "axios";
import { baseUri } from "./variables";

export const axiosInstance = axios.create({
    baseURL: baseUri,
    headers:{
        "Content-Type": "multipart/form-data",
    }
  })

  axiosInstance.interceptors.request.use(request=>{
    return request;
  },error =>{
    return Promise.reject(error)
  })
  
  axiosInstance.interceptors.response.use((response)=>{
    return response
  },(error)=>{
    return Promise.reject(error)
  })
