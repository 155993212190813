import React, { FC } from 'react'
import AImage from '../atoms/AImage'
import AText from '../atoms/AText'
import ATitle from '../atoms/ATitle'
import LinkText from '../atoms/LinkText'
import { baseImage } from '../../config/variables'

type Props = {
  item:any
}

const FavoriteItems: FC<Props> = ({item}) => {
 
  return (
    <div className=" w-[200px] h-[200px] bg-white shadow-sm rounded-xl m-2 flex flex-col p-3 hover:scale-105 duration-[0.3s]">
      <div className="">
      <ATitle
        type='h4'
        label={{
          text: item.nom_catalogue + " / "+ item.nom_categorie,
          style:'text-[9px] text-orange-500 uppercase'
        }}
      />
      <ATitle
        type='h3'
        label={{
          text:item.nom_prd,
          style:'font-semibold'
        }}
      />
      </div>
      <div className="w-full flex justify-center">
      <AImage
       src={baseImage+item.cover_prd}
        style="w-[100px] h-[100px] object-cover "
      />
      </div>
     <div className="flex justify-between items-end h-20">
     <AText style='text-[10px]' >{item.vente ? item.vente : 0} ventes</AText>
     <LinkText to={'/product/'+item.prd_token} style="bg-orange-500 text-white rounded-xl px-3 text-sm">voir</LinkText>
     </div>
    </div>
  )
}

export default FavoriteItems