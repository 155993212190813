import React, { FormEvent, useEffect, useState } from "react";
import ATitle from "../../components/atoms/ATitle";
import CategorieItem from "../../components/molecules/CategorieItem";
import allData from "../../api/categorie.json";
import { categorie } from "../../config/types";
import InputField from "../../components/molecules/forms/InputField";
import SelectField from "../../components/molecules/forms/SelectField";
import FileInput from "../../components/molecules/forms/FileInput";
import BtnForm from "../../components/molecules/forms/BtnForm";
import {
  alertDanger,
  alertSuccess,
  convertBase64,
} from "../../config/functions";
import Ord from "../../api/ordered.json";
import { ToastContainer, toast } from "react-toastify";
import {
  addPromotion,
  editPromotion,
  getAllPromotions,
  removePromotion,
} from "../../api/endpoint";
import PromotionItem from "../../components/molecules/promotion-item";

const HomePromotion = () => {
  const data = allData;
  const [add, setAdd] = useState<boolean>(false);
  const [marque, setMarque] = useState<any>("");
  const [modele, setModele] = useState<any>("");
  const [prix, setPrix] = useState<any>("");
  const [prix_avant, setPrixAvant] = useState<any>("");
  const [descr, setDescr] = useState<any>("");
  const [couleur, setCouleur] = useState<any>("");
  const [ordered, setOrdered] = useState<any>("");
  const [link, setLink] = useState<any>("");
  const [started_at, setStarted] = useState<any>("");
  const [ended_at, setEnded] = useState<any>("");
  const [icon, setIcon] = useState<any>("");
  const [preview, setPreview] = useState<any>("");
  const [currentPromotion, setCurrentPromotion] = useState<any>(null);
  const [promotion, setPromotion] = useState<Array<any>>([]);

  const toggleForm = (item: any) => {
    setCurrentPromotion(null);
    setAdd(!add);
    if (item) {
      setCurrentPromotion(item);
      // setPreview('')
      // setPreview(baseImage+ currentPromotion.icon)
    }
  };
  const handleProfil = async (e: any) => {
    const file = e.target.files[0];
    const preview = await convertBase64(e.target.files[0]);
    setIcon(file);
    setPreview(preview);
  };
  const getPromotions = () => {
    getAllPromotions().then((response) => {
      const { success, data } = response.data;
      if (success === 1) setPromotion(data);
      else setPromotion([]);
    });
  };
  const clear = () => {
    getPromotions();
    setMarque("");
    setModele("");
    setCouleur("");
    setCurrentPromotion("");
    setOrdered("");
    setEnded("");
    setStarted("");
    setOrdered("");
    setPrix("");
    setPrixAvant("");
    setDescr("");
    setIcon("");
    setPreview("");
    setLink("")
  };
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("marque", marque);
    formData.append("modele", modele);
    formData.append("prix", prix);
    formData.append("prix_avant", prix_avant);
    formData.append("descr", descr);
    formData.append("couleur", couleur);
    formData.append("ordered", ordered);
    formData.append("started_at", started_at);
    formData.append("ended_at", ended_at);
    formData.append("link", link);
    formData.append("icon", icon);
    if (add) {
      
      editPromotion(formData, currentPromotion.id).then((response) => {
        const { success, data } = response.data;
        if (success === 1) {
          clear();
          alertSuccess(data)
        }else console.log("erreur")
      });
    } else {
      if (!modele && !prix) {
        alertDanger("vous devez remplir le titre");
      } else {
        addPromotion(formData).then((response) => {
          const { success, data } = response.data;
          if (success === 1) {
            alertSuccess(data);
            clear();
          } else {
            alertDanger(data);
          }
        });
      }
    }
  };

  const onDelete = (e: any) => {
    removePromotion(e).then((response) => {
      const { success, data } = response.data;
      if (success === 1) {
        getPromotions();
        alertSuccess(data);
      } else {
        alertDanger(data);
      }
    });
  };
 

  useEffect(() => {
    getPromotions();
  }, []);
  return (
    <div className="w-full px-10">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ATitle
        type="h1"
        label={{
          text: "Promotions",
          style: "text-[24px] text-orange-500 uppercase",
        }}
      />
      <div className="flex justify-between">
        <div className="w-[45%]">
          <ATitle
            type="h3"
            label={{
              text: `${
                add ? "Modifier une promotion" : "Ajouter une promotion"
              } `,
              style: "text-[18px] text-gray-900 my-3",
            }}
          />
          {add ? (
            <button
              onClick={() => {
                setAdd(false);
                clear();
                setIcon("");
                setPreview("");
                setCurrentPromotion("");
              }}
              className="text-sm bg-blue-500 text-white px-3 py-1 rounded-xl mb-3"
            >
              Ajouter une promotion
            </button>
          ) : null}
          <div className="bg-white shadow-lg rounded-lg p-4">
            <form action="" method="post" onSubmit={onSubmit}>
              <InputField
                label="Marque"
                name="marque"
                value={marque}
                placeholder="entrer la marque"
                defaultValue={`${
                  currentPromotion ? currentPromotion.marque : ""
                }`}
                onChange={(text: any) => setMarque(text.target.value)}
              />

              <InputField
                label="Modele"
                name="modele"
                value={modele}
                placeholder="entrer le modele"
                defaultValue={`${
                  currentPromotion ? currentPromotion.modele : ""
                }`}
                onChange={(text: any) => setModele(text.target.value)}
              />

              <InputField
                label="Prix"
                name="prix"
                value={prix}
                placeholder="entrer le prix "
                defaultValue={`${
                  currentPromotion ? currentPromotion.prix : ""
                }`}
                onChange={(text: any) => setPrix(text.target.value)}
              />

              <InputField
                label="Prix precedent"
                name="prix_avant"
                value={prix_avant}
                placeholder="entrer le prix d'avant reduction "
                defaultValue={`${
                  currentPromotion ? currentPromotion.prix_avant : ""
                }`}
                onChange={(text: any) => setPrixAvant(text.target.value)}
              />

              <InputField
                label="Lien produit"
                name="link"
                value={link}
                placeholder="entrer le lien du ex. /produit/uihghjuiknh "
                defaultValue={`${
                  currentPromotion ? currentPromotion.link : ""
                }`}
                onChange={(text: any) => setLink(text.target.value)}
              />

              <div className="">
                <label htmlFor="">Descrition</label>
                <textarea
                  className="my-2 rounded-md p-3 outline-orange-500 transition-all duration-[0.3s] bg-gray-50 w-full"
                  onChange={(text: any) => setDescr(text.target.value)}
                  value={descr}
                >
                  {descr}
                </textarea>
              </div>

              <InputField
                label="Couleur"
                name="couleur"
                value={couleur}
                placeholder="couleur de text ex. #fffffe "
                defaultValue={`${
                  currentPromotion ? currentPromotion.couleur : ""
                }`}
                onChange={(text: any) => setCouleur(text.target.value)}
              />
              <SelectField
                label="Positionnement"
                name="ordered"
                optionField="Séléctionner"
                datas={Ord}
                onChange={(text: any) => setOrdered(text.target.value)}
              />
              <div className="">
                <label htmlFor="">Commence</label>
                <input
                  type="date"
                  className="my-2 rounded-md p-3 outline-orange-500 transition-all duration-[0.3s] bg-gray-50 w-full"
                  name="started_at"
                  id=""
                  value={started_at}
                  onChange={(text: any) => setStarted(text.target.value)}
                />
              </div>
              <div className="">
                <label htmlFor="">Prend fin</label>
                <input
                  type="date"
                  className="my-2 rounded-md p-3 outline-orange-500 transition-all duration-[0.3s] bg-gray-50 w-full"
                  name="started_at"
                  id=""
                  value={ended_at}
                  onChange={(text: any) => setEnded(text.target.value)}
                />
              </div>
              <FileInput
                name="icon"
                label="icon"
                value={preview}
                onChange={handleProfil}
              />

              <SelectField
                label="Active"
                name="active"
                optionField="Séléctionner"
                datas={[
                  { id: "1", label: "activer" },
                  { id: "0", label: "desactiver" },
                ]}
                onChange={(text: any) => setOrdered(text.target.value)}
                
              />
              <BtnForm
                value={add ? "enregistrer la modification" : "ajouter"}
              />
            </form>
          </div>
        </div>
        <div className="w-[53%] relative ">
          <ATitle
            type="h3"
            label={{
              text: "Liste",
              style: "text-[18px] text-gray-900 my-3",
            }}
          />
          <div className=" bg-white  min-h-[70vh]  w-[100%] p-3 rounded-lg">
            {promotion.length ? (
              promotion.map((item, index) => (
                <PromotionItem
                  data={item}
                  onToggle={toggleForm}
                  onDelete={onDelete}
                />
              ))
            ) : (
              <p>Aucune promotion ajouter pour l'instant</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePromotion;
