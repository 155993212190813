import React, { FC } from 'react'

type Props = {
    label: string
    name: string
    type?: string
    value: string
    defaultValue?: string
    placeholder?: string
    onChange: Function
}

const InputField : FC<Props> = ({label,name,type="text",defaultValue=null,placeholder="",onChange,value}) => {
    return (
        <div className="flex flex-col">
            <label htmlFor={name}>{label}</label>
            <input 
            type={type}
            id={name}
            placeholder={placeholder}
            className='my-2 rounded-md p-3 outline-orange-500 transition-all duration-[0.3s] bg-gray-50'
            value={`${value ? value : defaultValue}`} 
            onChange={(input)=>onChange(input)}
            />
            
        </div>
    )
}

export default InputField