import React, { FC } from 'react'
import ATitle from '../atoms/ATitle'
import LinkText from '../atoms/LinkText'
import { FaShippingFast } from 'react-icons/fa'

type Props = {
    item: any
}

const CommandeItem:FC<Props> = ({item}) => {
    console.log(item)
  return (
    <div className="flex items-center p-2 border-b-2 border-gray-50">
    <div className="w-[50px] h-[50px] rounded-full bg-slate-200 flex items-center justify-center mr-3">
    <FaShippingFast size={30}/>
    </div>
    <div className="">
        <ATitle
            type='h4'
            label={{
                text: "client",
                style: 'text-[9px] text-orange-500 uppercase'
            }}
        />
        <ATitle
            type='h3'
            label={{
                text: item.cl_fullname,
                style: 'font-semibold'
            }}
        />
        {/* <AText style='text-[14px]' >{item.total} $ USD</AText> */}
    </div>
    <p className=" rounded-xl px-5 text-sm flex items-center mr-2"> {item.data_created} </p>
    <LinkText to={"/livraison/"+item.cmd_token} style="bg-orange-500 text-white rounded-xl px-5 text-sm flex items-center mr-2"> voir commande</LinkText>
    {/* <LinkText to="/" style="bg-blue-500 text-white rounded-xl px-5 text-sm">livrer</LinkText> */}
</div>
  )
}

export default CommandeItem