import React from 'react'
import { BiTrash } from 'react-icons/bi'
import { FiEdit3 } from 'react-icons/fi'
import { baseImage } from '../../config/variables'
import AText from '../atoms/AText'

type Props = {
    data: any
    onToggle?:Function,
    onDelete?: Function
}

function PromotionItem({data, onToggle,onDelete}: Props) {
    const fullDate = (dat: Date)=>{
        const date = new Date(dat)
        return `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`
    }
  return (
   <>
     <div className=" w-full h-[40vh] rounded-xl shadow-xl my-2">
        <div className=" w-full h-[40vh] rounded-xl flex relative px-5 py-7 justify-between  items-center">
        <div className={`absolute top-0 left-0 w-full h-full add`} style={{background: data.couleur}}></div>

        <div className="w-[60%]">
            <h2>{data.marque}</h2>
            <h1 className={`font-bold text-[18px]`} style={{color: data.couleur}}>{data.modele}</h1>
            <p className="text-[10px] w-[60%] mt-2 text-[#212121]">{data.descr}</p>
            <p className="absolute bottom-2 left-[30%]  text-[10px] w-[60%]text-center mt-2 text-[#212121]"><span className='text-[16px]  font-bold' style={{color: data.couleur}}>{data.prix} $ USD</span><br/>  <span className="line-through">{data.prix_avant ? `(${data.prix_avant}) $ USD` : null}</span></p>
            <button className={`text-[10px] mt-2 text-white px-3 py-1 rounded-xl cursor-pointer`} style={{background: data.couleur}}>
                voir le produit
            </button>
        </div>
        <div className="w-[40%]  z-10">
            <img src={baseImage+data.cover} alt="" className="w-[70%]" />
        </div>
        </div>
    </div>
    <div className="flex mt-2 px-2 items-center bg-white shadow-md rounded-xl">
    {
    onToggle ?  <AText style="bg-blue-500 text-white rounded-[100%] text-sm w-[30px] h-[30px] m-1 flex justify-center items-center active:bg0-blue-800"><FiEdit3 onClick={()=> onToggle(data) } /></AText> : null
   }
    <AText style="bg-red-500 text-white rounded-[100%]  m-1 w-[30px] h-[30px] text-sm active:bg-red-800 flex justify-center items-center"><BiTrash onClick={()=> onDelete ? onDelete(data.id) : {}}/></AText>
    <div className="ml-1">
    <p className='text-[10px]'>Debut {fullDate(data.started_at)}</p>
    <p className='text-[10px]'>Fin {fullDate(data.ended_at)}</p>
    </div>
    </div>
   </>
  )
}

export default PromotionItem