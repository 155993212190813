import React, { FormEvent, useEffect, useState } from 'react'
import InputField from '../../components/molecules/forms/InputField'
import SelectField from '../../components/molecules/forms/SelectField'
import BtnForm from '../../components/molecules/forms/BtnForm'
import { createUser, getAllRoles } from '../../api/endpoint'
import { alertDanger, alertSuccess } from '../../config/functions'
import { ToastContainer } from 'react-toastify'

type Props = {}

const UserCreate = (props: Props) => {
    const [username, setUsername] = useState("")
    const [fullname, setFullname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [mdp, setmdp] = useState("")
    const [Cmdp, setCmdp] = useState("")
    const [role, setRole] = useState("")
    const [roleList, setRoleList] = useState([])

    const onSubmit = (form: FormEvent) => {
        form.preventDefault()

        const forms ={
            username,
            fullname,
            email,
            phone,
            role,
            mdp,
        }

        if(forms.username && forms.fullname && forms.email && forms.phone && forms.role && forms.mdp){
            if(mdp === Cmdp){
                createUser(forms)
                .then(response =>{
                    const {success, data} = response.data
                    if(success === 1){
                        alertSuccess(data);
                    }else alertDanger(data)
                })
            }else alertDanger("Les mots de passe doivent être identique")
        }else{
            alertDanger("vous devez remplir tout les champs")
        }
    }
   
    const getInfo = () => {
        getAllRoles()
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    setRoleList(data)
                }
            })
    }
    useEffect(() => {
        getInfo()
    }, [])
    return (
        <div className="px-10">
             <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
            <form action="" method="post" onSubmit={onSubmit}>
                <InputField
                    label="Nom d'utilisateur"
                    name="username"
                    value={username}
                    defaultValue={username}
                    placeholder="entrer le pseudonyme"
                    onChange={(text: any) => setUsername(text.target.value)}
                />
                <InputField
                    label="Nom Complet"
                    name="names"
                    value={fullname}
                    placeholder="entrer le nom complet"
                    onChange={(text: any) => setFullname(text.target.value)}
                    defaultValue={fullname}
                />
                 <InputField
                    label="E-mail"
                    name="email"
                    type="email"
                    value={email}
                    placeholder="entrer l'adresse e-mail"
                    onChange={(text: any) => setEmail(text.target.value)}
                    defaultValue={email}
                />
                 <InputField
                    label="Téléphone"
                    name="tel"
                    value={phone}
                    placeholder="entrer le numéro de telephone"
                    onChange={(text: any) => setPhone(text.target.value)}
                    defaultValue={phone}
                />
                 <InputField
                    label="Mot de passe"
                    name="pwd"
                    type='password'
                    value={mdp}
                    placeholder="entrer le mot de passe"
                    onChange={(text: any) => setmdp(text.target.value)}
                    defaultValue={mdp}
                />
                <InputField
                    label="Confirmation Mot de passe"
                    name="pwd"
                    type='password'
                    value={Cmdp}
                    placeholder="Confirmer le mot de passe"
                    onChange={(text: any) => setCmdp(text.target.value)}
                    defaultValue={Cmdp}
                />
                <SelectField
                    label="Role"
                    name="type"
                    optionField="Séléctionner un role"
                    datas={roleList}
                    onChange={(text: any) => setRole(text.target.value)}
                />
                <BtnForm value="creer" />
            </form>
        </div>
    )
}

export default UserCreate