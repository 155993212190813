import React, { FC } from 'react'
import AText from '../atoms/AText'
import ATitle from '../atoms/ATitle'
import Navigation, { useNavigate } from 'react-router-dom'
type Props = {
    data:{
        label:string,
        day:string,
    icon?:any,
    total?:string,
    to: string
}
}

const StatItem:FC<Props> = ({data}) => {
    const {label,icon,total,day, to}= data
    const navigation= useNavigate()
    return (
        <div className=" relative w-[250px] h-[80%] shadow-sm bg-white  rounded-lg mr-10 hover:scale-105 duration-[0.3s] p-5 flex items-center justify-between cursor-pointer" onClick={()=> navigation(to) }>
            <div className="">
                <ATitle
                    label={{
                        text: day,
                        style: 'text-[2em] text-orange-500'
                    }}
                />
                <AText style='text-gray-500 uppercase'>{label} </AText>
            </div>
            <div className="">
                {icon}
            </div>
            <AText style='absolute right-5 bottom-2 text-[11px] text-orange-200 flex items-center'>  {`${(total) ?'total :'+ total : ''}`} </AText>

        </div>
    )
}

export default StatItem