import { FC, useEffect, useState } from 'react'
import ATitle from '../atoms/ATitle'
import AlertStockItem from '../molecules/AlertStockItem'
import { ruptureStock } from '../../api/endpoint'
import { TbArticleOff } from 'react-icons/tb'


const AlertStock: FC = () => {
    const [stock, setStock] = useState<Array<any>>([])
    const getRuptureStock = () => {
        ruptureStock()
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    setStock(data)
                }
            })
    }
    useEffect(() => {
        getRuptureStock()
    }, [])
    return (
        <div className="w-[40%]">
            <ATitle label={{ text: "Produit en Rupture de stock" }} />
            {
                stock.length ? <div className="bg-white shadow-sm rounded-xl mt-5">
                    {stock.map((item, index) => <AlertStockItem key={index} item={item} />)}
                </div> : <div className="w-full h-[300px] bg-white flex flex-col items-center justify-center my-3">
                    <div className="w-[100px] h-[100px] rounded-full bg-slate-200 flex items-center justify-center mr-3">
                        <TbArticleOff size={50} className='text-orange-600' />
                    </div>
                    <p className='text-gray-600 py-3'>Aucun produit en rupture de stock pour le moment</p>
                </div>
            }
        </div>
    )
}

export default AlertStock