import React, { useEffect, useState } from 'react'
import { livraisons } from '../../api/endpoint'
import { Table } from 'flowbite-react'
import { TbHomeCancel } from 'react-icons/tb'
import { BsEye } from 'react-icons/bs'
import { Link } from 'react-router-dom'

type Props = {}

const LivraisonHome = (props: Props) => {
    const [liv, setLiv] = useState<Array<any>>([])
    const [tab, setTab] = useState<string>("attente")



    const getLivraisonLists = () => {
        livraisons().then(response => {
            const { data } = response.data
            setLiv(data)
        })
    }
    useEffect(() => {
        getLivraisonLists()
    }, [])
    return (
        <div className="px-5 md:px-10">
            <ul className="flex  w-[30%] items-center justify-between mb-5">
                <li onClick={()=> setTab("all")} className={'rounded-2xl px-3 text-sm font-light py-1 cursor-pointer hover:bg-orange-400 duration-150 ' + (tab=== "all" ? "bg-orange-400": "bg-white")}>Toutes</li>
                <li onClick={()=> setTab("attente")} className={'rounded-2xl px-3 text-sm font-light py-1 cursor-pointer hover:bg-orange-400 duration-150 ' + (tab=== "attente" ? "bg-orange-400": "bg-white")}>En attente</li>
                <li onClick={()=> setTab("liv")} className={'rounded-2xl px-3 text-sm font-light py-1 cursor-pointer hover:bg-orange-400 duration-150 ' + (tab=== "liv" ? "bg-orange-400": "bg-white")}>livrée</li>
                <li onClick={()=> setTab("cancel")} className={'rounded-2xl px-3 text-sm font-light py-1 cursor-pointer hover:bg-orange-400 duration-150 ' + (tab=== "cancel" ? "bg-orange-400": "bg-white")}>Annulée</li>
            </ul>
            <Table striped>
                <Table.Head>
                    <Table.HeadCell className='bg-orange-400'>
                        N°
                    </Table.HeadCell>
                    <Table.HeadCell className='bg-orange-400'>
                        commande du client
                    </Table.HeadCell>
                    <Table.HeadCell className='bg-orange-400'>
                        id commande
                    </Table.HeadCell>
                    <Table.HeadCell className='bg-orange-400'>
                        effectué le
                    </Table.HeadCell>
                    <Table.HeadCell className='bg-orange-400'>
                        livreur
                    </Table.HeadCell>
                    <Table.HeadCell className='bg-orange-400'>
                        status
                    </Table.HeadCell>
                    <Table.HeadCell className='bg-orange-400'>
                        action
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                {
                        liv?.length ? liv.filter(item => {
                            if(tab === "all")
                                return item
                            if(tab === "attente")
                                return  item.cancel == "0" && item.cmd_active == "0"
                            if(tab === "liv")
                                return item.current_pos == "livrée"
                            if(tab === "cancel")
                            return  item.cancel == "1"
                        }).map((item, index) =>   <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {index + 1}
                        </Table.Cell>
                        <Table.Cell>
                        {item?.cl_fullname}
                        </Table.Cell>
                        <Table.Cell>
                        {item?.cmd_token.toLocaleUpperCase()}
                        </Table.Cell>
                        <Table.Cell>
                        {item?.data_created}
                        </Table.Cell>
                        <Table.Cell>
                            {item?.lv_fullname ? item?.lv_fullname : "no attribué" }
                        </Table.Cell>
                        <Table.Cell>
                        {item?.lv_fullname ? (item?.cmd_active == "0" ? ((item?.cancel == "1") ? "annulée" : "en attente")  : "livrée") : (item?.cmd_active == "0" ? ((item?.cancel == "1") ? "annulée" : "en attente"): "livrée") }
                        </Table.Cell>
                        <Table.Cell>
                            <Link to={"/livraison/"+item?.cmd_token} className="bg-gray-300 flex items-center justify-center px-2 py-1 rounded-2xl text-black cursor-pointer">
                                <BsEye className='mr-2'/> detail
                            </Link>
                         
                        </Table.Cell>
                    </Table.Row>) : null
}
                   
                </Table.Body>
            </Table>
           
        </div>
    )
}

export default LivraisonHome