import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ATitle from '../atoms/ATitle'
import { BiCategory } from 'react-icons/bi'
import ItemSidebar from '../molecules/ItemSidebar'
import { AiOutlineDashboard, AiTwotoneSliders } from 'react-icons/ai'
import { FiSettings, FiUsers } from 'react-icons/fi'
import AImage from '../atoms/AImage'
import { FaOpencart } from 'react-icons/fa'
import { getDataStore } from '../../config/asyncStorage'
import { TbTruckDelivery } from 'react-icons/tb'
type Props = {}

const SideBar = (props: Props) => {
    const { pathname } = useLocation();
    const user = getDataStore('login-user')
    const navigate = useNavigate()
    
    useEffect(() => {
        if (!user) navigate('/login')
    }, [user])

    const menu = [
        {
            label: 'Tableau de bord',
            icon: <AiOutlineDashboard className='mr-3 text-2xl text-orange-500' />,
            goTo: '/'
        },
        {
            label: 'Produits',
            icon: <FaOpencart className='mr-3 text-2xl text-orange-500' />,
            goTo: '/products'
        },
        {
            label: 'Categorie',
            icon: <BiCategory className='mr-3 text-2xl text-orange-500' />,
            goTo: '/categories'
        },
        {
            label: 'Groupe produit',
            icon: <BiCategory className='mr-3 text-2xl text-orange-500' />,
            goTo: '/catalogue'
        },
        {
            label: 'Promotion',
            icon: <AiTwotoneSliders className='mr-3 text-2xl text-orange-500' />,
            goTo: '/promotion'
        },
        {
            label: 'Livraison',
            icon: <TbTruckDelivery className='mr-3 text-2xl text-orange-500' />,
            goTo: '/livraison'
        },
        {
            label: 'Utilisateur',
            icon: <FiUsers className='mr-3 text-2xl text-orange-500' />,
            goTo: '/users'
        },
        {
            label: 'Parametres',
            icon: <FiSettings className='mr-3 text-2xl text-orange-500' />,
            goTo: '/settings'
        },

    ];
    return (
        <div className='w-[20%] h-[100vh] bg-[#212121] pb-5'>
            {/* <ATitle 
       label={{
           text:'PORTAILS : 0.1.0',
           style:'text-orange-500 font-semibold'
       }}
       icon={<AImage  src='https://www.kindpng.com/picc/m/247-2472451_office-administration-administration-logo-hd-png-download.png' style='w-[35px] mr-3'/>}
       style="flex items-center justify-center"
       /> */}
            <div className="w-full h-10 bg-orange-500"></div>
            <ul className='mt-20 w-[100%]'>
                {
                    menu.map((item, index) => <ItemSidebar key={index}
                        label={
                            {
                                text: item.label,
                                style: `flex items-center  hover:text-orange-500 transition-all duration-[0.3s] h-[40px]  px-10 text-gray-100 hover:border-r-4 hover:border-orange-500 my-1 ${(item.goTo === pathname) ? 'border-r-4 border-orange-500' : null}`
                            }
                        }
                        icon={item.icon}
                        to={item.goTo}
                    />)
                }
            </ul>
        </div>
    )
}

export default SideBar