import React, { useEffect, useState } from 'react'
import StatItem from '../molecules/StatItem'
import { FaDollarSign, FaUsers } from 'react-icons/fa'
import { RiUserStarLine } from 'react-icons/ri'
import { BiCategory } from 'react-icons/bi'
import { FcMultipleSmartphones, FcSmartphoneTablet } from 'react-icons/fc'
import { TbTruckDelivery } from 'react-icons/tb'
import { countCategories, countLivraison, countProducts, countProductsSell, countRevenus, countSignUp, countVisitor } from '../../api/endpoint'
type Props = {}

const Statistique = (props: Props) => {
    const [visitors, setVisitors] = useState<any>(null)
    const [signUp, setsignUp] = useState<any>(null)
    const [cats, setcats] = useState<any>(null)
    const [products, setproducts] = useState<any>(null)
    const [sells, setsells] = useState<any>(null)
    const [revenus, setrevenus] = useState<any>(null)
    const [livraison, setlivraison] = useState<any>(null)

    const getCountVisitors = () => {
        countVisitor()
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    setVisitors(data)
                }
            })
    }

    const getCountSignUp = () => {
        countSignUp()
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    setsignUp(data)
                }
            })
    }
    const getCountCategores = () => {
        countCategories()
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    setcats(data)
                }
            })
    }
    const getCountProducts = () => {
        countProducts()
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    setproducts(data)
                }
            })
    }
    const getCountSells = () => {
        countProductsSell()
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    setsells(data)
                }
            })
    }
    const getCountRevenus = () => {
        countRevenus()
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    setrevenus(data)
                }
            })
    }

    const getCountLivraison = () => {
        countLivraison()
            .then(response => {
                const { success, data } = response.data
                if (success === 1) {
                    setlivraison(data)
                }
            })
    }
    useEffect(() => {
        getCountVisitors()
    }, [])
    useEffect(() => {
        getCountSignUp()
    }, [])
    useEffect(() => {
        getCountCategores()
    }, [])
    useEffect(() => {
        getCountProducts()
    }, [])
    useEffect(() => {
        getCountSells()
    }, [])
    useEffect(() => {
        getCountRevenus()
    }, [])
    useEffect(() => {
        getCountLivraison()
    }, [])

    const stats = [
        {
            label: 'visiteurs',
            icon: <FaUsers className='text-[3em]' />,
            total: visitors?.all,
            day: visitors?.today,
            to: "visiteur"
        },
        {
            label: 'inscrits',
            icon: <RiUserStarLine className='text-[3em]' />,
            total: signUp?.all,
            day: signUp?.today,
            to: "users"
        },
        {
            label: 'Categories',
            icon: <BiCategory className='text-[3em]' />,
            day: cats?.all,
            to: "catalogue"
        },
        {
            label: 'produits',
            icon: <FcSmartphoneTablet className='text-[3em]' />,
            day: products?.all,
            to: "products"
        },
        {
            label: 'produits vendus',
            icon: <FcMultipleSmartphones className='text-[3em]' />,
            day: sells?.today ? sells?.today : 0,
            total: sells?.all ? sells?.all : 0,
            to: "ventes"

        },
        {
            label: 'revenus',
            icon: <FaDollarSign className='text-[3em] text-green-600' />,
            day: revenus?.today ? revenus?.today : 0 +' $',
            total: revenus?.all ? revenus?.all : 0 +' $',
            to: "revenu"

        },
        {
            label: 'Livraison en attente',
            icon: <TbTruckDelivery className='text-[3em] text-blue-600' />,
            day: livraison?.today,
            total: livraison?.all,
            to: "livraison"
        },
    ]
    return (
        <div className=" w-[100%]  overflow-x-scroll px-10 scroll-m-0 hid">
            <div className="w-max h-40  flex items-center" >
                {
                    stats.map((item, index) => <StatItem data={item} key={index} />)
                }
            </div>
        </div>
    )
}

export default Statistique