
import React from 'react'
import AImage from '../../components/atoms/AImage'
import AText from '../../components/atoms/AText'
import { getDataStore } from '../../config/asyncStorage'

type Props = {}

const AccountScreen = (props: Props) => {
    const user = getDataStore('login-user')
    console.log(user)
  return (
    <div className="w-full  h-full px-10 flex flex-col items-center justify-center  ">
            <AImage src={"https://www.simplitoo.fr/media/articles/preview_Iqvnb2A.jpeg"} style="rounded-full w-[200px] h-[200px] object-cover "/>
            <AText style="text-black text-[2em]">{user.nom_complet}</AText>
            <AText style="text-black text-md">{user.username}</AText>
        </div>
  )
}

export default AccountScreen