import React, { FC } from 'react'
import AText from '../atoms/AText'
import ATitle from '../atoms/ATitle'
import LinkText from '../atoms/LinkText'
import RoundedImage from './RoundedImage'
import { baseImage } from '../../config/variables'

type Props = {
    item: any
}

const AlertStockItem: FC<Props> = ({ item }) => {
    return (
        <div className="flex items-center border-b-2 border-gray-50">
            <RoundedImage
                src={baseImage+item.cover_prd}
                style="w-[80px] h-[80px] mr-5"
            />
            <div className="w-[55%]">
                <ATitle
                    type='h4'
                    label={{
                        text: item.nom_catalogue + " / " + item.nom_categorie,
                        style: 'text-[9px] text-orange-500 uppercase'
                    }}
                />
                <ATitle
                    type='h3'
                    label={{
                        text: item.nom_prd,
                        style: 'font-semibold'
                    }}
                />
                <AText style='text-[10px]' >{item.quantite} restant(s)</AText>
            </div>
            <LinkText to={"/product/"+ item.prd_token} style="bg-orange-500 text-white rounded-xl px-5 text-sm">voir</LinkText>
        </div>
    )
}

export default AlertStockItem