import { toast } from "react-toastify";

export const convertBase64 = (file: File) =>{
    return new Promise((resolve, reject) =>{
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        
        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}
export const alertDanger = (message: string)=>{
    toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
} 

export const alertSuccess = (message: string) =>{
    toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
}