import React, { FormEvent, useEffect, useState } from 'react'
import AText from '../../components/atoms/AText';
import ATitle from '../../components/atoms/ATitle';
import RoundedImage from '../../components/molecules/RoundedImage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../states/store';
import { clearUserError, userLogin } from '../../states/actions/usersActions';
import { useNavigate } from "react-router-dom";
import { getDataStore } from '../../config/asyncStorage';

type Props = {}

const Login = (props: Props) => {
  const [username, setUsername] = useState<any>('');
  const [password, setPassword] = useState<any>('');
  const { userData, userError, userLoading, userErrorMessage } = useSelector((state: RootState) => state.user)
  const user = getDataStore('login-user')
  
  let navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch()
  useEffect(() => {
    if (user) navigate('/')
}, [user])
  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (username != '' && password != '') {
      dispatch(userLogin({ username, password }))
    }else{
      toast.error("vous devez remplir tous les champs", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  useEffect(() => {
    if (userError) {
      toast.error(userErrorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => dispatch(clearUserError())
      });
    }
  }, [userError, userErrorMessage])

  useEffect(() => {
    if (userData) {

      toast.success(' connexion reussi', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(()=>{
        navigate("/");
      }, 1000)
    }
  }, [userData])

  return (
    <div className="relative w-full h-[100vh] bg-gray-200 flex items-center justify-center">

      <form className="w-[60%] md:w-[25%] h-[300px] z-40" onSubmit={onSubmit} >
        <div className="flex justify-center items-center mb-5">
          <RoundedImage
            src='https://cdn1.vectorstock.com/i/1000x1000/11/10/admin-icon-male-person-profile-avatar-with-gear-vector-25811110.jpg'
            style="w-[30px] h-[30px] mr-2" />
          <ATitle
            label={{
              text: 'Connexion',
              style: 'font-semibold text-center text-[22px]'
            }}
          />
        </div>
        <div className="flex flex-col">
          <input type="text" id="username" placeholder="Nom d'utilisateur" className='my-4 rounded-md p-3 outline-orange-500 transition-all duration-[0.3s]' onChange={(text) => setUsername(text.target.value)} value={username} />
          <input type="password" id="pwd" placeholder="Mot de passe" className='rounded-md p-3 outline-orange-500 transition-all duration-[0.3s]' onChange={(text) => setPassword(text.target.value)} value={password} />
          <button className='bg-orange-500 text-white rounded-md py-3 my-3' type='submit' >se connecter</button>
        </div>

      </form>
      <AText style="absolute bottom-2 text-gray-500 z-10"> <AText type='span' style="text-orange-500">@2022, {'</>'} AkajoDev. </AText> | P-0.1.0</AText>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='absolute bottom-0 '><path fill="#f97316" fillOpacity="0.1" d="M0,192L80,197.3C160,203,320,213,480,208C640,203,800,181,960,165.3C1120,149,1280,139,1360,133.3L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default Login;