import React, { FC } from 'react'

type Props = {
    style?: string | Object,
    children: React.ReactNode,
    type?: string
}

const AText: FC<Props> = ({children,style,type='p'}) => {
    const selectType = ()=>{
       switch(type){
           case 'span':
               return <span className={`${style}`}>{children}</span>
           default:
               return <p className={`${style}`}>{children}</p>
       }
    }
  return (
    selectType()
  )
}

export default AText