import React from 'react'
import { BsEye, BsTrash } from 'react-icons/bs'
import { FaEdit } from 'react-icons/fa'
import { baseImage } from '../../config/variables'
import LinkText from '../atoms/LinkText'

type Props = {
    data: any,
    onDelete?: Function,
    onDetail: Function
}

function ProductItem({data,onDelete, onDetail}: Props) {
   
  return (
    <div className="flex items-center bg-white py-2 px-3 w-full md:w-[45%] m-3 rounded-xl">
        <img src={baseImage+data.cover_prd} alt="" className="object-cover w-[80px] h-[80px] rounded-[50%]"/>
        <div className="ml-4">
            <h3 className='font-semibold text-sm'>{data.nom_prd}</h3>
            <h5 className='font-light text-[10px]'><span className="text-blue-500">{data.nom_catalogue}</span> {' > '}{data.nom_categorie}</h5>
            <h5 className={`font-light text-[10px] text-white w-max px-2 py-1 rounded-full ${data.active == '1' ? 'bg-green-500' : 'bg-red-500'}`}>{data.active == "1" ? "en ligne" : "en attente"}</h5>
            <p className="mt-2 text-[12px]">
             <span className="font-semibold">USD :</span>  {data.prixUSD} $ USD <br/>
             <span className="font-semibold">CDF :</span> {data.prixCDF} CDF <br/>
             <span className="font-semibold">Quantité :</span> {data.quantite} <br/>
            </p>
        </div>
        <div className="ml-4 flex">
          <LinkText to={`/product/${data.prd_token}`} style="bg-blue-500 w-max px-2 py-1 text-white m-[1px] rounded-full flex justify-center items-center">
            <BsEye />
          </LinkText>
          <LinkText to={`/product/edit/${data.prd_token}`}  style="bg-green-500 w-max px-2 py-1 text-white m-[1px] rounded-full flex justify-center items-center">
            <FaEdit/>
          </LinkText>
          <div onClick={()=> onDelete ? onDelete(data.prd_id) : {}} className="bg-red-500 w-max px-2 py-1 text-white m-[1px] rounded-full flex justify-center items-center">
            <BsTrash/>
          </div>
        </div>
    </div>
  )
}

export default ProductItem