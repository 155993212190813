import React, { FormEvent, useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  editProduct,
  getCatalogues,
  getCatCatById,
  getProductById,
} from "../../api/endpoint";
import ATitle from "../../components/atoms/ATitle";
import LinkText from "../../components/atoms/LinkText";
import BtnForm from "../../components/molecules/forms/BtnForm";
import FileInput from "../../components/molecules/forms/FileInput";
import InputField from "../../components/molecules/forms/InputField";
import SelectField from "../../components/molecules/forms/SelectField";
import {
  alertDanger,
  alertSuccess,
  convertBase64,
} from "../../config/functions";

type Props = {};

function EditProduct({}: Props) {
  const { id } = useParams();
  const navigation = useNavigate();
  const [prd, setPrd] = useState<{
    prd_id: string;
    nom_prd: string;
    description: string;
    slug_prd: string;
    prixUSD: string | number;
    prixCDF: string | number;
    quantite: string | number;
    cover_prd: string;
    created_time: string | Date;
    active: string | boolean;
    prd_token: string;
    id_catg: string | number;
    nom_catalogue: string;
    id_cat: string | number;
    nom_categorie: string;
    galeries: Array<any>;
  } | null>(null);
  const [data, setData] = useState([]);
  const [icon, setIcon] = useState<any>("");
  const [preview, setPreview] = useState<any>("");
  const [titre, setTitre] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [cat, setCat] = useState<any>("");
  const [prixUSD, setPrixUSD] = useState<any>("");
  const [prixCDF, setPrixCDF] = useState<any>("");
  const [qte, setQte] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [catalogues, setCatalogues] = useState<Array<any>>([]);
  const [categories, setCategories] = useState<Array<any>>([]);

  const getProd = () => {
    getProductById(id)
      .then((response) => {
        const { data, success } = response.data;
        if (success == 1) {
          setPrd(data);
          setTitre(data.nom_prd);
          setPrixCDF(data.prixCDF);
          setPrixUSD(data.prixUSD);
          setQte(data.quantite);
          setDescription(data.description);
        } else {
          alert("ce produit n'existe pas veuillez saisi un bon url");
          navigation("/");
        }
      })
      .catch((error) => {
        alert("vous devez passer une url valide");
        navigation("/");
      });
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("titre", titre);
    formData.append("type", type);
    formData.append("cat", cat);
    formData.append("icon", icon);
    formData.append("prixUSD", prixUSD);
    formData.append("prixCDF", prixCDF);
    // formData.append("qte", qte);
    formData.append("description", description);

    editProduct(formData, prd!.prd_id).then((response) => {
      console.log(response);
      const { data, success } = response.data;
      if (success == 1) {
        alertSuccess(data);
    
      } else {
        alertDanger(data);
      }
    });
  };

  const getAllCatalogue = () => {
    getCatalogues().then((response) => {
      const { success, data } = response.data;
      if (success == 1) {
        setCatalogues(data);
      }
    });
  };

  const getAllCat = (id: string) => {
    getCatCatById(id).then((response) => {
      const { success, data } = response.data;
      if (success === 1) {
        setCategories(data);
      }
    });
  };

  const handleProfil = async (e: any) => {
    const file = e.target.files[0];
    const preview = await convertBase64(e.target.files[0]);
    setIcon(file);
    setPreview(preview);
  };

  useEffect(() => {
    getAllCatalogue();
  }, []);

  useEffect(() => {
    if (type) {
      getAllCat(type);
    }
  }, [type]);

  useEffect(() => {
    getProd();
  }, []);
  return (
    <div className="w-full px-10 ">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LinkText
        to="/products"
        style="flex items-center cursor-pointer bg-blue-500 rounded-xl text-white w-max px-3"
      >
        <BsArrowLeft /> <span className="ml-2">retour</span>
      </LinkText>
      <div className="w-full px-10">
        <ATitle
          type="h3"
          label={{
            text: "Modification produit",
            style: "text-[18px] text-gray-900 my-3",
          }}
        />
        <div className="bg-white shadow-lg rounded-lg p-4">
          <form action="" method="post" onSubmit={onSubmit}>
            <InputField
              label="Nom produit"
              name="titre"
              value={titre}
              placeholder="entrer le titre du produit"
              defaultValue={titre}
              onChange={(text: any) => setTitre(text.target.value)}
            />

            <SelectField
              label="Catalogue"
              name="type"
              optionField="Séléctionner"
              datas={catalogues}
              onChange={(text: any) => setType(text.target.value)}
            />

            {type && (
              <SelectField
                label="Categorie"
                name="type"
                optionField="Séléctionner"
                datas={categories}
                onChange={(text: any) => setCat(text.target.value)}
              />
            )}
            <InputField
              label="Prix USD"
              name="prixUsd"
              type="number"
              value={prixUSD}
              defaultValue={prixUSD}
              placeholder="entrer le montant"
              onChange={(text: any) => setPrixUSD(text.target.value)}
            />

            <InputField
              type="number"
              label="Prix CDF"
              name="prixCdf"
              value={prixCDF}
              defaultValue={prixCDF}
              placeholder="entrer le montant"
              onChange={(text: any) => setPrixCDF(text.target.value)}
            />
            {/* <InputField
              type="number"
              label="Quantité"
              name="qte"
              value={qte}
              defaultValue={qte}
              placeholder="entrer le montant"
              onChange={(text: any) => setQte(text.target.value)}
            /> */}
            <div className="">
              <label htmlFor="">Descrition</label>
              <textarea
                className="my-2 rounded-md p-3 outline-orange-500 transition-all duration-[0.3s] bg-gray-50 w-full"
                onChange={(text: any) => setDescription(text.target.value)}
                value={description}
              >
                {description}
              </textarea>
            </div>
            <FileInput
              name="icon"
              label="Image de couverture"
              value={preview}
              onChange={handleProfil}
            />
            <BtnForm value="ajouter" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
